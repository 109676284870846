import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Reports } from "./Reports";
import { Create } from "./Create";
import { Check } from "./Check";
import { Manage } from "./Manage";
import { Scan } from "./Scan";
import { AdminMenu } from "./AdminMenu";
import { AdminFinalise } from "./AdminFinalise";
import { StaffBarcode } from "./StaffBarcode";
import { AdminBlockSubCat } from "./AdminBlockSubCat";
import store from "../../store";
import { getAccessData } from "../../App";
import { Bays } from "./Bays";
import { useLocation } from "react-router-dom";
import { AdminAdminUnlock } from "./AdminAdminUnlock";

export const StockTakeBody = () => {
  const [userid, ,] = store.useState("userid");
  const [page, setPage] = useState("menu");

  const [StockTakeBay, setStockTakeBay] = useState("");
  const [From, setFrom] = useState("");

  const emptyFromPage = {
    Page: "",
    StoreNo: "",
    StoreName: "",
    UserID: "",
  };
  const [FromPage, setFromPage] = useState(emptyFromPage);

  const [CurrentStockTake, setCurrentStockTake] = useState("");
  const [IsCurrentStockTakeStarted, setIsCurrentStockTakeStarted] = useState(false);

  const [Access, setAccess] = useState({});

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams !== null) {
      const params = new URLSearchParams(searchParams);

      const _from = params.get("f") || "";
      const _fromStore = params.get("s") || "";
      const _fromStoreName = params.get("n") || "";
      const _fromUserID = params.get("u") || "";
      setFromPage({
        Page: _from,
        StoreNo: _fromStore,
        StoreName: _fromStoreName,
        UserID: _fromUserID,
      });

      async function getAccess() {
        setAccess(await getAccessData(_fromUserID));
      }
      getAccess();
    }
  }, [location]);

  const handleClick_Home = () => {
    if (process.env.NODE_ENV === "production") {
      let uri = "";
      switch (FromPage.Page) {
        case "STORE":
          uri = "http://192.168.15.201/TM";
          break;
        default:
        case "HO":
          uri = "/#/";
          break;
      }
      window.location.href = uri;
    } else {
      window.location.href = "/#/";
    }
  };

  const menuPage = (
    <Menu
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      IsCurrentStockTakeStarted={IsCurrentStockTakeStarted}
      setIsCurrentStockTakeStarted={setIsCurrentStockTakeStarted}
      Access={Access}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const scanPage = (
    <Scan
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const checkPage = (
    <Check
      setPage={setPage}
      setStockTakeBay={setStockTakeBay}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const createPage = (
    <Create
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const managePage = (
    <Manage
      setPage={setPage}
      StockTakeBay={StockTakeBay}
      setStockTakeBay={setStockTakeBay}
      From={From}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const reportsPage = (
    <Reports
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const adminMenuPage = (
    <AdminMenu
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const adminFinaliseStockTakePage = (
    <AdminFinalise
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const staffBarcodePage = (
    <StaffBarcode
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const adminBlockSubCatPage = (
    <AdminBlockSubCat
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const baysPage = (
    <Bays
      page={page}
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const adminAdminUnlockPage = (
    <AdminAdminUnlock
      setPage={setPage}
      handleClick_Home={handleClick_Home}
      FromPage={FromPage}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "scan":
        return scanPage;
      case "check":
        return checkPage;
      case "create":
        return createPage;
      case "manage":
        return managePage;
      case "reports":
        return reportsPage;
      case "admin-menu":
        return adminMenuPage;
      case "admin-finalise":
        return adminFinaliseStockTakePage;
      case "staffbarcode":
        return staffBarcodePage;
      case "admin-blocksubcat":
        return adminBlockSubCatPage;
      case "bays":
        return baysPage;
      case "admin-adminunlock":
        return adminAdminUnlockPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
