import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { getDistinctValuesFromArray } from "../../functions/GetDistinctValuesFromArray";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { isInputWholeInteger } from "../../functions/IsInputWholeInteger";

export const PopupApproveOrder = (props) => {
  const [userid, ,] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");

  const [Items, setItems] = useState([]);
  const [ApproveItems, setApproveItems] = useState([]);

  const [ShowWHInStock, setShowWHInStock] = useState("ALL");

  const refWHInStock_All = useRef();
  const refWHInStock_Yes = useRef();
  const refWHInStock_No = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);

    setItems([]);
    setShowWHInStock("ALL");
  };

  const handleShow = () => {
    setPopupResponse("");
    getItems();
  };

  const getItems = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PendingOrders_Items_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          CartID: props.SelectedOrder.CartID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItems(
          spResponse.Table.map((item) => ({
            CartID: item.CartID,
            CartLineID: item.CartLineID,
            SKU: item.SKU,
            Description: item.Description,
            WarehouseInStock: item.WarehouseInStock,
            "Qty Requested": item["Qty Requested"],
          }))
        );

        setApproveItems(
          spResponse.Table.map((item) => ({
            SKU: item.SKU,
            ApprovedQty: item["Qty Requested"],
          }))
        );
      } else {
        setItems([]);
        setApproveItems([]);
      }
    }
  };

  const handleChange_ApprovedQty = (_sku, _qty) => {
    setPopupResponse("");

    setApproveItems(
      ApproveItems.map((item) => {
        if (item.SKU === _sku) {
          return {
            ...item,
            ApprovedQty: _qty,
          };
        }

        return item;
      })
    );
  };

  const handleClick_Deny = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PendingOrders_Deny`, {
      method: "post",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          CartID: props.SelectedOrder.CartID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
        props.getOrders();
        props.setShow(false);
        handleClose();
      }
    }
  };

  const handleClick_Approve = async () => {
    // check if all qty are whole integers
    if (ApproveItems.map((item) => isInputWholeInteger(item.ApprovedQty)).includes(false)) {
      setPopupResponse("ERROR!: Invalid Approved Qty(s).");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PendingOrders_Approve`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            ApproveItems.map((item) => ({
              UserID: userid,
              CartID: props.SelectedOrder.CartID,
              SKU: item.SKU,
              ApprovedQty: item.ApprovedQty,
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setPopupResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          props.setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
          props.getOrders();
          props.setShow(false);
          handleClose();
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h1>
              <strong>Approve order?</strong>
            </h1>
            <p className="error-message">{PopupResponse}</p>
            <div className="input-row">
              <p>
                <strong>Show WH In Stock Items</strong>
              </p>
              <div className="popup-radio-container">
                <input
                  ref={refWHInStock_All}
                  type="radio"
                  id="all"
                  name="whinstock"
                  value="ALL"
                  onChange={(e) => handleChange_Input(e, setShowWHInStock)}
                  className="btnHover no-highlight"
                  defaultChecked
                />
                <label
                  className="btnHover no-highlight"
                  htmlFor="all"
                >
                  All
                </label>
                <input
                  ref={refWHInStock_Yes}
                  type="radio"
                  id="yes"
                  name="whinstock"
                  value="1"
                  onChange={(e) => handleChange_Input(e, setShowWHInStock)}
                  className="btnHover no-highlight"
                />
                <label
                  className="btnHover no-highlight"
                  htmlFor="yes"
                >
                  Yes
                </label>
                <input
                  ref={refWHInStock_No}
                  type="radio"
                  id="no"
                  name="whinstock"
                  value="0"
                  onChange={(e) => handleChange_Input(e, setShowWHInStock)}
                  className="btnHover no-highlight"
                />
                <label
                  className="btnHover no-highlight"
                  htmlFor="no"
                >
                  No
                </label>
              </div>
            </div>
            <table className="itemdetails-table no-highlight">
              <thead>
                <tr>
                  <th rowSpan={2}>SKU</th>
                  <th rowSpan={2}>Item</th>
                  <th rowSpan={2}>WH In Stock</th>
                  <th colSpan={2}>Qty</th>
                </tr>
                <tr>
                  <th>Requested</th>
                  <th>Approved</th>
                </tr>
              </thead>
              <tbody>
                {Items.length > 0 &&
                  Items.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        !["ALL", item.WarehouseInStock.toString()].includes(
                          ShowWHInStock.toString()
                        ) && "hidden"
                      }
                    >
                      <td>{item.SKU}</td>
                      <td>{item.Description}</td>
                      <td>{item.WarehouseInStock === 1 ? "Yes" : "No"}</td>
                      <td>{item["Qty Requested"]}</td>
                      <td>
                        <input
                          type="text"
                          defaultValue={item["Qty Requested"]}
                          onChange={(e) => handleChange_ApprovedQty(item.SKU, e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Deny}
            >
              <strong>Deny</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Approve}
            >
              <strong>Approve</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
