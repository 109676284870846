import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAccessData, orange, spURL } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { getDistinctValuesFromArray } from "../../functions/GetDistinctValuesFromArray";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { PopupAddToCart } from "./PopupAddToCart";
import { PopupOrder } from "./PopupOrder";
import { useInitialEffect } from "../../hooks/useInitialEffect";

export const Order = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [ItemList, setItemList] = useState([]);

  const [SelectedCategory, setSelectedCategory] = useState("ALL");

  const emptyItem = {
    SKU: "",
    Category: "",
    Item: "",
  };
  const [SelectedItem, setSelectedItem] = useState(emptyItem);

  const [CartID, setCartID] = useState(0);
  const [CartItems, setCartItems] = useState([]);

  const [showAddToCart, setShowAddToCart] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  const getCart = async (_signal) => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_CartList_Get`, {
      method: "POST",
      signal: _signal,
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCartID(spResponse.Table[0].CartID);
      } else {
        setCartID(0);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setCartItems(
          spResponse.Table1.map((item) => ({
            CartLineID: item.CartLineID,
            Category: item.Category,
            SKU: item.SKU,
            Description: item.Description,
            WarehouseInStock: item.WarehouseInStock,
            Qty: item.Qty,
          }))
        );
      } else {
        setCartItems([]);
      }
    }
  };

  const getItems = async (_signal) => {
    setWarehouseOrderingResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_ItemList_Get`, {
      method: "POST",
      signal: _signal,
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemList(
          spResponse.Table.map((item) => ({
            SKU: item.SKU,
            Category: item.Category,
            Item: item.Item,
          }))
        );
      } else {
        setItemList([]);
      }
    }
  };

  const handleClick_Order = async () => {
    setWarehouseOrderingResponse("");
    setShowOrder(true);
  };

  useInitialEffect(getCart);
  useInitialEffect(getItems);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Category:</strong>
          </p>
          <select
            onChange={(e) => {
              setWarehouseOrderingResponse("");
              handleChange_Input(e, setSelectedCategory);
            }}
          >
            <option value="ALL">ALL</option>
            {getDistinctValuesFromArray(ItemList.map((item) => item.Category)).map((cat, index) => (
              <option
                value={cat}
                key={index}
              >
                {cat}
              </option>
            ))}
          </select>
        </div>

        <p className="error-message">{WarehouseOrderingResponse}</p>

        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Item</th>
            </tr>
          </thead>
          <tbody>
            {ItemList.filter((item) => ["ALL", item.Category].includes(SelectedCategory)).map(
              (item, index) => (
                <tr
                  key={index}
                  className="btnHover no-highlight"
                  onClick={() => {
                    setWarehouseOrderingResponse("");
                    setSelectedItem(item);
                    setShowAddToCart(true);
                  }}
                >
                  <td>{item.SKU}</td>
                  <td>{item.Item}</td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <PopupAddToCart
          show={showAddToCart}
          setShow={setShowAddToCart}
          CartID={CartID}
          SelectedItem={SelectedItem}
          setWarehouseOrderingResponse={setWarehouseOrderingResponse}
          getCart={getCart}
          Type="Add"
        />

        <PopupOrder
          show={showOrder}
          setShow={setShowOrder}
          CartID={CartID}
          CartItems={CartItems}
          setWarehouseOrderingResponse={setWarehouseOrderingResponse}
          getCart={getCart}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Order"
          colour={orange}
          onClick={handleClick_Order}
        />
      </div>
    </div>
  );
};
