import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { blue, green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupCameraScreen } from "./PopupCameraScreen";
import is from "is_js";

export const TaskMobile = (props) => {
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState("");

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstorecompliancetoolimages"); // create a containerClient

  const emptyTaskDetails = {
    SubmissionID: 0,
    TaskID: 0,
    TaskTitle: "",
    TaskDescription: "",
    TaskDueDate: "",
    IsImageRequired: "",
    SampleImageURL: "",
    Submitted: 0,
    SubmissionComment: "",
    DateSubmitted: "",
  };
  const [TaskDetails, setTaskDetails] = useState(emptyTaskDetails);

  const emptyTaskImage = {
    ImageLineNo: 0,
    ImageURL: "",
  };
  const [TaskImages, setTaskImages] = useState(emptyTaskImage);

  const emptyUploadedImageFile = {
    name: "",
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const refSubmissionComment = useRef();

  const [showCamera, setShowCamera] = useState(false);

  const handleClick_Back = () => {
    props.setStoreComplianceToolResponse("");
    props.setSelectedSubmissionID("");
    props.setPage("tasklist");
  };

  const getTaskDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceTool_Task_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SubmissionID: props.SelectedSubmissionID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setTaskDetails({
          SubmissionID: spResponse.Table[0].SubmissionID,
          TaskID: spResponse.Table[0].TaskID,
          TaskTitle: spResponse.Table[0].TaskTitle,
          TaskDescription: spResponse.Table[0].TaskDescription,
          TaskDueDate: spResponse.Table[0].TaskDueDate,
          IsImageRequired: spResponse.Table[0].IsImageRequired,
          SampleImageURL: spResponse.Table[0].SampleImageURL,
          Submitted: spResponse.Table[0].Submitted,
          SubmissionComment: spResponse.Table[0].SubmissionComment,
          DateSubmitted: spResponse.Table[0]["Date Submitted"],
        });

        refSubmissionComment.current.value = spResponse.Table[0].SubmissionComment;

        if (spResponse.Table[0].Submitted === 1) {
          refSubmissionComment.current.disabled = true;
        }
      } else {
        setTaskDetails(emptyTaskDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setTaskImages({
          ImageLineNo: spResponse.Table1[0].ImageLineNo,
          ImageURL: spResponse.Table1[0].ImageURL,
        });
      } else {
        setTaskImages(emptyTaskImage);
      }
    }
  };

  const handleClick_Done = async () => {
    if (TaskDetails.IsImageRequired === 1) {
      if (UploadedImageFile.blob === "") {
        setStoreComplianceToolResponse("ERROR!: Image not uploaded.");
      } else {
        const blobClient = containerClient.getBlockBlobClient(UploadedImageFile.name);

        trackPromise(blobClient.uploadData(UploadedImageFile.blob))
          .then(async () => {
            await submitTask();
          })
          .catch((err) => {
            setStoreComplianceToolResponse(`ERROR!: Image upload error. ${err}`);
          });
      }
    } else {
      await submitTask();
    }
  };

  const submitTask = async () => {
    const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstorecompliancetoolimages/${encodeURIComponent(
      UploadedImageFile.name
    )}`;

    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceTool_Task_Submit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SubmissionID: TaskDetails.SubmissionID,
          SubmissionComment: refSubmissionComment.current.value,
          ImageURL: blobURL,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStoreComplianceToolResponse(spResponse.Table[0].OutputString);
        props.setPage("tasklist");
      }
    }
  };
  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          setUploadedImageFile({ name: `${TaskDetails.SubmissionID}.jpg`, blob: imgBlob });
        })
    );
  };

  useEffect(() => {
    getTaskDetails();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Title:</strong>
          </p>
          <p>{TaskDetails.TaskTitle}</p>
        </div>
        {TaskDetails.Submitted === 1 && (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Submitted on:</strong>
            </p>
            <p>{ConvertDateFormat(TaskDetails.DateSubmitted, "en-CA")}</p>
          </div>
        )}
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            rows="10"
            value={TaskDetails.TaskDescription}
            disabled
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Comment:</strong>
          </p>
          <textarea
            ref={refSubmissionComment}
            rows="10"
            placeholder="Enter comment here..."
          ></textarea>
        </div>
        {TaskDetails.IsImageRequired === 1 && (
          <>
            <div>
              <p>
                <strong>Sample Image</strong>
              </p>
              <img src={TaskDetails.SampleImageURL} />
            </div>
            <br />
            <div className="blockedorders-option-row">
              <div className="storeaudittool-fileupload-container">
                {TaskDetails.Submitted === 1 ? (
                  <img
                    className="storeaudittool-image"
                    src={TaskImages.ImageURL}
                  />
                ) : UploadedImageFile.blob === "" ? (
                  <MenuButton
                    className="no-highlight"
                    button="UP"
                    colour={blue}
                    title="Upload Photo"
                    onClick={() => setShowCamera(true)}
                  />
                ) : (
                  <>
                    <img
                      className="storeaudittool-image btnHover"
                      src={URL.createObjectURL(UploadedImageFile.blob)}
                      onClick={() => setShowCamera(true)}
                    />
                    <FontAwesomeIcon
                      className="storeaudittool-deleteimage-icon btnHover"
                      icon="fas fa-times"
                      onClick={() => {
                        setUploadedImageFile(emptyUploadedImageFile);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <p className="error-message">{StoreComplianceToolResponse}</p>

        <PopupCameraScreen
          show={showCamera}
          setShow={setShowCamera}
          processSnappedImage={processSnappedImage}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={blue}
          onClick={props.handleClick_Home}
        />
        {TaskDetails.Submitted === 0 && (
          <FooterButton
            button="Done"
            colour={blue}
            onClick={handleClick_Done}
          />
        )}
      </div>
    </div>
  );
};
