import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { orange, spURL, yellow } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const Menu = (props) => {
  const [storeno, ,] = store.useState("storeno");

  const [TheftReceiptResponse, setTheftReceiptResponse] = useState("");

  const [ReceiptList, setReceiptList] = useState([]);

  const getDDLReceipt = async (signal = null) => {
    setTheftReceiptResponse("");
    setReceiptList([]);

    const resObj = await runFetch(`${spURL}Handheld_TheftReceipt_Receipts_Get`, {
      method: "POST",
      signal: signal,
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setTheftReceiptResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setReceiptList(spResponse.Table);
      }
    }
  };

  useInitialEffect(getDDLReceipt);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Receipt</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {ReceiptList.map((receipt, index) => (
              <tr
                key={index}
                className="btnHover"
                onClick={() => {
                  setTheftReceiptResponse("");
                  props.setSelectedTheftReceipt(receipt);
                  props.setPage("add");
                }}
              >
                <td>{receipt.TheftReceiptID}</td>
                <td>{receipt.TheftReceiptName}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className="error-message">{TheftReceiptResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={yellow}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={yellow}
          onClick={() => props.setPage("create")}
        />
      </div>
    </div>
  );
};
