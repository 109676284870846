import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupRelease } from "./PopupRelease";

import { PopupSKUList } from "./PopupSKUList";

export const PickConfirm = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [OrderList, setOrderList] = useState([]);

  const [SelectedPicks, setSelectedPicks] = useState([]);

  const [show, setShow] = useState(false);
  const [showRelease, setShowRelease] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Header_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrderList(
          spResponse.Table.map((item) => {
            switch (item.Status) {
              case "Complete":
                return {
                  CartID: item.CartID,
                  Status: item.Status,
                  FontColour: "lightgreen",
                };
              case "Incomplete":
                return {
                  CartID: item.CartID,
                  Status: item.Status,
                  FontColour: "red",
                };
              default:
                return {
                  CartID: item.CartID,
                  Status: item.Status,
                  FontColour: "black",
                };
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setPage(`pick-lines`);
  };

  const handleClick_Release = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Commit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          SelectedPicks.map((item) => ({
            CartID: item.CartID,
            UserID: userid,
            Status: item.Status,
          }))
        ),
      }),
    });

    if (resObj.exception !== "") {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setPage("pick-header");
    }
  };

  const handleClick_List = () => {
    setShow(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          {OrderList.length !== 0 && (
            <>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderList.map((item, index) => (
                    <tr
                      onClick={() => {
                        setWarehouseOrderingResponse("");

                        let wasPickSelected = SelectedPicks.map((pick) => pick.CartID).includes(
                          item.CartID
                        );

                        if (wasPickSelected) {
                          setSelectedPicks(
                            SelectedPicks.filter((pick) => pick.CartID !== item.CartID)
                          );
                        } else {
                          setSelectedPicks((values) => [
                            ...values,
                            { CartID: item.CartID, Status: item.Status },
                          ]);
                        }
                      }}
                      key={index}
                      className={`btnHover ${
                        SelectedPicks.map((pick) => pick.CartID).includes(item.CartID)
                          ? "background-lightblue"
                          : "background-white"
                      }`}
                    >
                      <td>{item.CartID}</td>
                      <td style={{ color: item.FontColour }}>{item.Status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <p>
                <strong>No. of orders: </strong>
                {OrderList.length}
              </p>
            </>
          )}
        </div>
        <p className="error-message">{WarehouseOrderingResponse}</p>

        <PopupSKUList
          show={show}
          setShow={setShow}
          OrderList={OrderList}
        />

        <PopupRelease
          show={showRelease}
          setShow={setShowRelease}
          handleClick_Release={handleClick_Release}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="List"
          colour={orange}
          onClick={handleClick_List}
        />
        <FooterButton
          button="Rel"
          colour={orange}
          onClick={() => {
            setShowRelease(true);
          }}
        />
      </div>
    </div>
  );
};
