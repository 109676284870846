import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Webcam from "react-webcam";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCamera, faX, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

library.add(faCamera);
library.add(faX);
library.add(faRotateLeft);

export const PopupCameraScreen = (props) => {
  const [SnappedImage, setSnappedImage] = useState(null);
  const [CameraList, setCameraList] = useState([]);

  const [SelectedCameraDeviceID, setSelectedCameraDeviceID] = useState("");

  const refCamera = useRef();
  const refSelectCamera = useRef();

  const cleanImage = () => {
    setSnappedImage(null);
  };

  const getListOfCameras = () => {
    if (navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const cameraDevices = devices.filter((device) => device.kind === "videoinput");
        setCameraList(cameraDevices);

        if (cameraDevices.length > 0) {
          setSelectedCameraDeviceID(cameraDevices[cameraDevices.length - 1].deviceId);
          refSelectCamera.current.value = cameraDevices[cameraDevices.length - 1].deviceId;
        }
      });
    } else {
      setCameraList([]);
      setSelectedCameraDeviceID("");
    }
  };

  const handleClose = () => {
    cleanImage();
    props.setShow(false);
  };

  const handleShow = () => {
    cleanImage();
    getListOfCameras();

    if (CameraList.length > 0) {
      setSelectedCameraDeviceID(CameraList[CameraList.length - 1].deviceId);
      refSelectCamera.current.value = CameraList[CameraList.length - 1].deviceId;
    }
  };

  const handleChange_Camera = (e) => {
    // setSelectedCameraDeviceID(CameraList.filter((camera) => camera.deviceId === e.target.value));
    setSelectedCameraDeviceID(e.target.value);
  };

  const handleClick_Retake = () => {
    cleanImage();
  };

  const handleClick_Snap = () => {
    setSnappedImage(refCamera.current.getScreenshot());
  };

  const handleClick_Save = () => {
    props.processSnappedImage(SnappedImage);
    handleClose();
  };

  useEffect(() => {
    getListOfCameras();
  }, []);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        fullscreen
        centered
      >
        <Modal.Body>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Camera:</strong>
            </p>
            <select
              onChange={handleChange_Camera}
              ref={refSelectCamera}
            >
              {CameraList.map((camera) => (
                <option value={camera.deviceId}>{camera.label}</option>
              ))}
            </select>
          </div>
          <div className="storeaudittool-camera-preview">
            <Webcam
              ref={refCamera}
              className={SnappedImage && "hidden"}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                deviceId: SelectedCameraDeviceID,
                width: 300,
                height: 450,
              }}
            />
            <img
              className={!SnappedImage && "hidden"}
              src={SnappedImage}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            {SnappedImage ? (
              <button
                className="popup-button"
                onClick={handleClick_Retake}
              >
                <FontAwesomeIcon icon="fa-solid fa-rotate-left" />
              </button>
            ) : (
              <button
                className="popup-button"
                onClick={handleClick_Snap}
              >
                <FontAwesomeIcon icon="fa-solid fa-camera" />
              </button>
            )}
            <button
              className={`popup-button ${!SnappedImage && "visibility-hidden"}`}
              onClick={handleClick_Save}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
