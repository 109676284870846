import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  getAccessData,
  red,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
} from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";
import { MenuButton } from "../../components/HomepageButton";
import { PopupBayHistory } from "./PopupBayHistory";
import { PopupBayItemList } from "./PopupBayItemList";
import { PopupPhoto } from "./PopupPhoto";

export const PopupBayFunction = (props) => {
  const [Access, setAccess] = useState({});

  const [PopupResponse, setPopupResponse] = useState("");

  const [showHistory, setShowHistory] = useState(false);
  const [showChangeQty, setShowChangeQty] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = async () => {
    setAccess(await getAccessData(props.FromPage.UserID));
  };

  const handleClick_Function = async (_function) => {
    setPopupResponse("");
    const resObj = await runFetch(`${spURL}Stocktake_Function_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: props.FromPage.StoreNo,
          BayNo: props.SelectedBay.BayNo,
          StockTake: props.CurrentStockTake,
          Function: _function,
          From: "",
          Quantity: "",
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStockTakeResponse(spResponse.Table[0].OutputString);
        props.getBaysTable();
        props.setShow(false);
      }
    }
  };

  const handleClick_ChangeQty = () => {
    setPopupResponse("");
    setShowChangeQty(true);
  };

  const handleClick_History = () => {
    setPopupResponse("");
    setShowHistory(true);
  };

  const handleClick_Photo = () => {
    setPopupResponse("");
    setShowPhoto(true);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Bay: </strong>
              {props.SelectedBay.BayNo}
            </p>
            {props.SelectedBay.Status === "ADMIN LOCKED" ? (
              Access.StockTakeAdmin && (
                <div className="home-table">
                  <MenuButton
                    title="Admin Unlock"
                    colour={red}
                    button="AU"
                    onClick={() => handleClick_Function("ADMIN UNLOCK")}
                  />
                </div>
              )
            ) : (
              <>
                <div className="home-table">
                  {props.SelectedBay.Status === "LOCKED" ? (
                    <MenuButton
                      title="Unlock"
                      colour={red}
                      button="U"
                      onClick={() => handleClick_Function("UNLOCK BAY")}
                    />
                  ) : (
                    <MenuButton
                      title="Lock"
                      colour={red}
                      button="L"
                      onClick={() => handleClick_Function("LOCK BAY")}
                    />
                  )}

                  <MenuButton
                    title="Recount"
                    colour={red}
                    button="R"
                    onClick={() => handleClick_Function("RECOUNT BAY")}
                  />
                  <MenuButton
                    title="Use Recount"
                    colour={red}
                    button="UR"
                    onClick={() => handleClick_Function("USE RECOUNT FOR BAY")}
                  />
                </div>
                <div className="home-table">
                  <MenuButton
                    title="History"
                    colour={red}
                    button="H"
                    onClick={handleClick_History}
                  />
                  <MenuButton
                    title="Change Qty"
                    colour={red}
                    button="CQ"
                    onClick={handleClick_ChangeQty}
                  />
                  {Access.StockTakeAdmin && (
                    <MenuButton
                      title="Admin Lock"
                      colour={red}
                      button="AL"
                      onClick={() => handleClick_Function("ADMIN LOCK")}
                    />
                  )}
                </div>
                <div className="home-table">
                  <MenuButton
                    title="Photo"
                    colour={red}
                    button="P"
                    onClick={handleClick_Photo}
                  />
                </div>
              </>
            )}
          </div>
          <br />
          <p className="error-message">{PopupResponse}</p>

          <PopupBayHistory
            show={showHistory}
            setShow={setShowHistory}
            SelectedBay={props.SelectedBay}
            CurrentStockTake={props.CurrentStockTake}
            FromPage={props.FromPage}
          />

          <PopupBayItemList
            show={showChangeQty}
            setShow={setShowChangeQty}
            SelectedBay={props.SelectedBay}
            CurrentStockTake={props.CurrentStockTake}
            FromPage={props.FromPage}
          />

          <PopupPhoto
            show={showPhoto}
            setShow={setShowPhoto}
            SelectedBay={props.SelectedBay}
            CurrentStockTake={props.CurrentStockTake}
            FromPage={props.FromPage}
          />

          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
