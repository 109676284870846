import { useRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const AdminAdminUnlock = (props) => {
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [ddlStockTake, setDDLStockTake] = useState([]);

  const refStockTake = useRef();
  const refStartBay = useRef();
  const refEndBay = useRef();

  const handleClick_Back = () => {
    setStockTakeResponse("");
    props.setPage("admin-menu");
  };

  const handleClick_Unlock = async () => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}StocktakeAdmin_AdminUnlock`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StockTake: refStockTake.current.value,
          StartBay: refStartBay.current.value,
          EndBay: refEndBay.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStockTakeResponse(spResponse.Table[0].OutputString);
      }
    }
    resetOption();
    getDDLStockTake();
  };

  const resetOption = () => {
    refStockTake.current.value = "";
    refStartBay.current.value = "";
    refEndBay.current.value = "";
  };

  const getDDLStockTake = async () => {
    const resObj = await runFetch(`${spURL}StockTake_List_StockTake`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.FromPage.StoreNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLStockTake(spResponse.Table);
      }
    }
  };

  useEffect(() => {
    getDDLStockTake();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <div className="stocktake-check-container">
          <div className="stocktake-create-input-row">
            <p>
              <strong>Store:</strong>
            </p>
            <p>{props.FromPage.StoreName}</p>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>StockTake:</strong>
            </p>
            <select
              id="stocktake-admin-finalisestocktake-stocktake"
              ref={refStockTake}
            >
              <option
                value=""
                name=""
              ></option>
              {ddlStockTake.map((item, index) => (
                <option
                  key={index}
                  value={item.Description}
                >
                  {item.Description}
                </option>
              ))}
            </select>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Bays:</strong>
            </p>
            <div className="from-to-text-container">
              <input
                type="input"
                required
                ref={refStartBay}
              />
              {" - "}
              <input
                type="input"
                required
                ref={refEndBay}
              />
            </div>
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick_Unlock}
          >
            Unlock
          </button>
          <br />
          <p className="error-message">{StockTakeResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={red}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
