import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { getAccessData, orange, spURL } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { getDistinctValuesFromArray } from "../../functions/GetDistinctValuesFromArray";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { PopupAddToCart } from "./PopupAddToCart";
import { PopupOrder } from "./PopupOrder";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { use } from "react";
import { PopupCreateItem } from "./PopupCreateItem";

export const ItemMaintenance = (props) => {
  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [ItemList, setItemList] = useState([]);

  const [SelectedCategory, setSelectedCategory] = useState("ALL");
  const [SelectedWHInStock, setSelectedWHInStock] = useState("ALL");

  const emptyItem = {
    SKU: 0,
    Category: "",
    Item: "",
  };
  const [SelectedItem, setSelectedItem] = useState(emptyItem);

  const [showCreateItem, setShowCreateItem] = useState(false);

  const refWHInStock_All = useRef();
  const refWHInStock_Yes = useRef();
  const refWHInStock_No = useRef();

  const getItems = async (_signal) => {
    setWarehouseOrderingResponse("");
    setSelectedItem(emptyItem);

    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_ItemList_Get`, {
      method: "POST",
      signal: _signal,
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemList(
          spResponse.Table.map((item) => ({
            SKU: item.SKU,
            Category: item.Category,
            Item: item.Item,
            WarehouseInStock: item.WarehouseInStock,
          }))
        );
      } else {
        setItemList([]);
      }
    }
  };

  const handleClick_Create = () => {
    setWarehouseOrderingResponse("");
    setShowCreateItem(true);
  };

  const handleClick_Toggle = async () => {
    const resObj = await runFetch(
      `${spURL}Handheld_WarehouseOrdering_ItemMaintenance_ToggleWarehouseInStock`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            SKU: SelectedItem.SKU,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        getItems();
        setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
      }
    }
  };

  useInitialEffect(getItems);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Category:</strong>
          </p>
          <select
            onChange={(e) => {
              setWarehouseOrderingResponse("");
              setSelectedItem(emptyItem);
              handleChange_Input(e, setSelectedCategory);
            }}
          >
            <option value="ALL">ALL</option>
            {getDistinctValuesFromArray(ItemList.map((item) => item.Category)).map((cat, index) => (
              <option
                value={cat}
                key={index}
              >
                {cat}
              </option>
            ))}
          </select>
        </div>
        <div className="input-row">
          <p>
            <strong>Warehouse In Stock:</strong>
          </p>
          <div className="popup-radio-container">
            <input
              ref={refWHInStock_All}
              type="radio"
              id="all"
              name="whinstock"
              value="ALL"
              onChange={(e) => {
                setWarehouseOrderingResponse("");
                setSelectedItem(emptyItem);
                handleChange_Input(e, setSelectedWHInStock);
              }}
              className="btnHover no-highlight"
              defaultChecked
            />
            <label
              className="btnHover no-highlight"
              htmlFor="all"
            >
              All
            </label>
            <input
              ref={refWHInStock_Yes}
              type="radio"
              id="yes"
              name="whinstock"
              value="1"
              onChange={(e) => {
                setWarehouseOrderingResponse("");
                setSelectedItem(emptyItem);
                handleChange_Input(e, setSelectedWHInStock);
              }}
              className="btnHover no-highlight"
            />
            <label
              className="btnHover no-highlight"
              htmlFor="yes"
            >
              Yes
            </label>
            <input
              ref={refWHInStock_No}
              type="radio"
              id="no"
              name="whinstock"
              value="0"
              onChange={(e) => {
                setWarehouseOrderingResponse("");
                setSelectedItem(emptyItem);
                handleChange_Input(e, setSelectedWHInStock);
              }}
              className="btnHover no-highlight"
            />
            <label
              className="btnHover no-highlight"
              htmlFor="no"
            >
              No
            </label>
          </div>
        </div>

        <p className="error-message">{WarehouseOrderingResponse}</p>

        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Item</th>
              <th>WH In Stock</th>
            </tr>
          </thead>
          <tbody>
            {ItemList.filter(
              (item) =>
                ["ALL", item.Category].includes(SelectedCategory) &&
                ["ALL", item.WarehouseInStock.toString()].includes(SelectedWHInStock)
            ).map((item, index) => (
              <tr
                key={index}
                className={`btnHover no-highlight ${
                  item.SKU === SelectedItem.SKU && "background-lightblue"
                }`}
                onClick={() => {
                  setWarehouseOrderingResponse("");

                  if (item.SKU === SelectedItem.SKU) {
                    setSelectedItem(emptyItem);
                  } else {
                    setSelectedItem(item);
                  }
                }}
              >
                <td>{item.SKU}</td>
                <td>{item.Item}</td>
                <td>{item.WarehouseInStock === 1 ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PopupCreateItem
          show={showCreateItem}
          setShow={setShowCreateItem}
          ItemList={ItemList}
          getItems={getItems}
          setWarehouseOrderingResponse={setWarehouseOrderingResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => props.setPage("admin")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        {SelectedItem.SKU === 0 && (
          <FooterButton
            button="Create"
            colour={orange}
            onClick={handleClick_Create}
          />
        )}
        {SelectedItem.SKU !== 0 && (
          <>
            <FooterButton
              button="Toggle"
              colour={orange}
              onClick={handleClick_Toggle}
            />
          </>
        )}
      </div>
    </div>
  );
};
