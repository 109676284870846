import { useRef, useState } from "react";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { useId } from "react";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { generateFormJSON } from "../../functions/GenerateFormJSON";
import store from "../../store";

export const WHSIncident = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [FormResponse, setFormResponse] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [JobTitleList, setJobTitleList] = useState([]);

  const AffectedBodyPart_Head = useId();
  const AffectedBodyPart_RightHand = useId();
  const AffectedBodyPart_LeftHand = useId();
  const AffectedBodyPart_Fingers = useId();
  const AffectedBodyPart_Face = useId();
  const AffectedBodyPart_RightKnee = useId();
  const AffectedBodyPart_LeftKnee = useId();
  const AffectedBodyPart_RightAnkle = useId();
  const AffectedBodyPart_LeftAnkle = useId();
  const AffectedBodyPart_Abdomen = useId();
  const AffectedBodyPart_RightEye = useId();
  const AffectedBodyPart_LeftEye = useId();
  const AffectedBodyPart_RightLeg = useId();
  const AffectedBodyPart_LeftLeg = useId();
  const AffectedBodyPart_Nose = useId();
  const AffectedBodyPart_Trunk = useId();
  const AffectedBodyPart_RightFoot = useId();
  const AffectedBodyPart_LeftFoot = useId();
  const AffectedBodyPart_Ears = useId();
  const AffectedBodyPart_Neck = useId();
  const AffectedBodyPart_RightArm = useId();
  const AffectedBodyPart_LeftArm = useId();
  const AffectedBodyPart_Other = useId();
  const FirstAidGiven_FirstAidGivenOnSite = useId();
  const FirstAidGiven_AmbulanceCalled = useId();
  const FirstAidGiven_ReferredToGP = useId();
  const FirstAidGiven_FirstAidNotNecessary = useId();
  const FirstAidGiven_Other = useId();

  const refForm = useRef();

  const handleFormSubmit = async () => {
    setFormResponse("");

    let isFormValid = refForm.current.checkValidity();
    let formData = new FormData(refForm.current);

    if (!isFormValid) {
      refForm.current.reportValidity();
    } else {
      const resObj = await runFetch(`${spURL}Handheld_Forms_Log_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
            StoreNo: storeno,
            FormType: "WHS Incident Form",
            FormContentJSON: generateFormJSON(formData),
          }),
        }),
      });

      if (resObj.response === null) {
        setFormResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setFormResponse(spResponse.Table[0].OutputString);

        refForm.current.reset();
      }
    }
  };

  const getStoreList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_StoreList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(spResponse.Table.map((item) => item.StoreName));
      }
    }
  };

  const getJobTitleList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_JobTitleList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setJobTitleList(spResponse.Table.map((item) => item.JobTitle));
      }
    }
  };

  useInitialEffect(getStoreList);
  useInitialEffect(getJobTitleList);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1>
          <strong>WHS & Customer Incident</strong>
        </h1>
        <hr />
        <p className="error-message">{FormResponse}</p>
        <form ref={refForm}>
          <h5>
            <strong>Store Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Name:*</strong>
              </p>
              <select
                name="StoreName"
                required
              >
                <option value=""></option>
                {StoreList.map((storename) => (
                  <option value={storename}>{storename}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Phone:</strong>
              </p>
              <input
                type="text"
                name="StorePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Report:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfReport"
                required
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported by:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedBy_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported to:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedTo_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Incident Information</strong>
          </h5>
          <p>The two employees listed above must complete this form together.</p>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Incident:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfIncident"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Type of Incident:*</strong>
              </p>
              <select
                required
                name="TypeOfIncident"
              >
                <option value="">--Please choose an option--</option>
                <option value="Customer Injury">Customer Injury</option>
                <option value="Teammate Injury">Teammate Injury</option>
                <option value="Hazard">Hazard</option>
                <option value="Near Miss">Near Miss</option>
                <option value="Property Damage">Property Damage</option>
                <option value="Stock Damage">Stock Damage</option>
              </select>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Exact Location of Incident:*</strong>
            </p>
            <input
              type="text"
              required
              name="IncidentLocation"
            />
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Injured Person's Name</strong>
              </p>
              <input
                type="text"
                required
                name="InjuredPersonName"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Injured Person's Phone</strong>
              </p>
              <input
                type="text"
                required
                name="InjuredPersonPhone"
              />
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Injured Person's Address:*</strong>
            </p>
            <input
              type="text"
              required
              name="InjuredPersonAddress"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If contractor - Company Name:</strong>
            </p>
            <input
              type="text"
              name="InjuredPersonCompanyName"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Cause of Injury:*</strong>
            </p>
            <select
              required
              name="CauseOfInjury"
            >
              <option value=""></option>
              <option value="Pushing/Pulling">Pushing/Pulling</option>
              <option value="Trip/Slip/Fall">Trip/Slip/Fall</option>
              <option value="Falling Object">Falling Object</option>
              <option value="Moving Plant">Moving Plant</option>
              <option value="Biological">Biological</option>
              <option value="Chemical">Chemical</option>
              <option value="Cut">Cut</option>
              <option value="Bruise">Bruise</option>
              <option value="Sprain/Strain">Sprain/Strain</option>
              <option value="Fracture">Fracture</option>
              <option value="Burn">Burn</option>
              <option value="Abrasion">Abrasion</option>
              <option value="Vehicle">Vehicle</option>
              <option value="Person/Animal">Person/Animal</option>
              <option value="Electric Shock">Electric Shock</option>
              <option value="Other (Please Specify)">Other (Please Specify)</option>
            </select>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If Other, Please Describe:</strong>
            </p>
            <input
              type="text"
              name="CauseOfInjury_Other"
            />
          </div>
          <p>
            <strong>What Body Part was Affected:* (Select all that apply)</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Head}
                name="AffectedBodyPart"
                value="Head"
              />
              <label htmlFor={AffectedBodyPart_Head}>Head</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightHand}
                name="AffectedBodyPart"
                value="Hand (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightHand}>Hand (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftHand}
                name="AffectedBodyPart"
                value="Hand (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftHand}>Hand (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Fingers}
                name="AffectedBodyPart"
                value="Fingers"
              />
              <label htmlFor={AffectedBodyPart_Fingers}>Fingers</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Face}
                name="AffectedBodyPart"
                value="Face"
              />
              <label htmlFor={AffectedBodyPart_Face}>Face</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightKnee}
                name="AffectedBodyPart"
                value="Knee (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightKnee}>Knee (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftKnee}
                name="AffectedBodyPart"
                value="Knee (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftKnee}>Knee (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightAnkle}
                name="AffectedBodyPart"
                value="Ankle (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightAnkle}>Ankle (Right)</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftAnkle}
                name="AffectedBodyPart"
                value="Ankle (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftAnkle}>Ankle (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Abdomen}
                name="AffectedBodyPart"
                value="Abdomen"
              />
              <label htmlFor={AffectedBodyPart_Abdomen}>Abdomen</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightEye}
                name="AffectedBodyPart"
                value="Eye (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightEye}>Eye (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftEye}
                name="AffectedBodyPart"
                value="Eye (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftEye}>Eye (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightLeg}
                name="AffectedBodyPart"
                value="Leg (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightLeg}>Leg (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftLeg}
                name="AffectedBodyPart"
                value="Leg (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftLeg}>Leg (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Nose}
                name="AffectedBodyPart"
                value="Nose"
              />
              <label htmlFor={AffectedBodyPart_Nose}>Nose</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Trunk}
                name="AffectedBodyPart"
                value="Trunk/Back"
              />
              <label htmlFor={AffectedBodyPart_Trunk}>Trunk/Back</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightFoot}
                name="AffectedBodyPart"
                value="Foot (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightFoot}>Foot (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftFoot}
                name="AffectedBodyPart"
                value="Foot (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftFoot}>Foot (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Ears}
                name="AffectedBodyPart"
                value="Ears"
              />
              <label htmlFor={AffectedBodyPart_Ears}>Ears</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Neck}
                name="AffectedBodyPart"
                value="Neck"
              />
              <label htmlFor={AffectedBodyPart_Neck}>Neck</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_RightArm}
                name="AffectedBodyPart"
                value="Arm (Right)"
              />
              <label htmlFor={AffectedBodyPart_RightArm}>Arm (Right)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_LeftArm}
                name="AffectedBodyPart"
                value="Arm (Left)"
              />
              <label htmlFor={AffectedBodyPart_LeftArm}>Arm (Left)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={AffectedBodyPart_Other}
                name="AffectedBodyPart"
                value="Other"
              />
              <label htmlFor={AffectedBodyPart_Other}>Other</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If Other, Please Describe:</strong>
            </p>
            <input
              type="text"
              name="AffectedBodyPart_Other"
            />
          </div>
          <p>
            <strong>Was First Aid Given? (Select all that apply)</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={FirstAidGiven_FirstAidGivenOnSite}
                name="FirstAidGiven"
                value="First Aid given on site"
              />
              <label htmlFor={FirstAidGiven_FirstAidGivenOnSite}>First Aid Given On Site</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={FirstAidGiven_AmbulanceCalled}
                name="FirstAidGiven"
                value="Ambulance Called"
              />
              <label htmlFor={FirstAidGiven_AmbulanceCalled}>Ambulance Called</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={FirstAidGiven_ReferredToGP}
                name="FirstAidGiven"
                value="Referred to GP"
              />
              <label htmlFor={FirstAidGiven_ReferredToGP}>Referred to GP</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={FirstAidGiven_FirstAidNotNecessary}
                name="FirstAidGiven"
                value="First Aid Not Necessary"
              />
              <label htmlFor={FirstAidGiven_FirstAidNotNecessary}>First Aid Not Necessary</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={FirstAidGiven_Other}
                name="FirstAidGiven"
                value="Other"
              />
              <label htmlFor={FirstAidGiven_Other}>Other</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If Other, Please Describe</strong>
            </p>
            <input
              type="text"
              name="FirstAidGiven_Other"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Incident Details:*</strong>
            </p>
            <textarea
              rows={5}
              required
              name="IncidentDetails"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>What immediate actions were taken:*</strong>
            </p>
            <textarea
              rows={5}
              required
              name="ActionsTaken"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>
                What long term actions can be implemented to prevent this from occurring again:*
              </strong>
            </p>
            <textarea
              rows={5}
              name="Prevention"
              required
            ></textarea>
          </div>
          <hr />
          <h5>
            <strong>Witness Information</strong>
          </h5>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Job Title</th>
                <th>Contact Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_1"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_1">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_1"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_2"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_2">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_2"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_3"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_3">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_3"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div className="footer-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={purple}
          onClick={handleFormSubmit}
        />
      </div>
    </div>
  );
};
