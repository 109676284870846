import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";

export const PopupSKUList = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const [Orders, setOrders] = useState([]);

  const [SKUList, setSKUList] = useState([]);

  const [qtyTotal, setQtyTotal] = useState(0);
  const [pkdTotal, setPkdTotal] = useState(0);

  useEffect(() => {
    let qtyTotal = 0;
    let pkdTotal = 0;

    for (let i = 0; i < SKUList.length; i++) {
      qtyTotal += SKUList[i].Qty;
      pkdTotal += SKUList[i].Pkd;
    }

    setQtyTotal(qtyTotal);
    setPkdTotal(pkdTotal);
  }, [SKUList]);

  const handleClose = () => {
    props.setShow(false);
    setOrders([]);
    setConfirmResponse("");
    setSKUList([]);
  };

  const handleShow = () => {
    setOrders(
      props.OrderList.map((item) => ({
        CartID: item.CartID,
      }))
    );
  };

  const getSKUList = async (selectedOrder) => {
    setConfirmResponse("");

    if (selectedOrder !== "") {
      const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_SKUList_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            Orders.filter((item) => item.CartID === parseInt(selectedOrder)).map((item) => ({
              CartID: item.CartID,
            }))
          ),
        }),
      });

      if (resObj.exception !== "") {
        setConfirmResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setSKUList(
            spResponse.Table.map((item) => ({
              SKU: item.SKU,
              Description: item.Description,
              Qty: item.Qty,
              Pkd: item.Pkd,
            }))
          );
        } else {
          setSKUList([]);
          setConfirmResponse("No SKUs found in order");
        }
      }
    } else {
      setSKUList([]);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>OrderID</strong>
              </p>
              <select
                name="skulist-orderid"
                id="skulist-orderid"
                onChange={(e) => handleChange_Input(e, getSKUList)}
              >
                <option></option>
                {Orders.map((item, index) => (
                  <option key={index}>{item.CartID}</option>
                ))}
              </select>
            </div>
            <div style={{ overflow: "auto", maxHeight: 300 }}>
              <table className="itemdetails-table">
                <thead>
                  <th>SKU</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Pkd</th>
                </thead>
                <tbody>
                  {SKUList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.SKU}</td>
                      <td>{item.Description}</td>
                      <td>{item.Qty}</td>
                      <td>{item.Pkd}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <p>
              <strong>Qty Total: </strong>
              {qtyTotal}
            </p>
            <p>
              <strong>Pkd Total: </strong>
              {pkdTotal}
            </p>
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
