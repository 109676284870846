import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styl
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { purple, red, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton, ContentButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const AfterHoursSales = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [AfterHoursSalesResponse, setAfterHoursSalesResponse] = useState("");
  const [AfterHoursSalesReportValues, setAfterHoursSalesReportValues] = useState([]);
  const refStartDate = useRef();
  const refEndDate = useRef();

  const getDailySalesPerformanceReport = async () => {
    const resObj = await runFetch(`${spURL}Handheld_AfterHours_Sales_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          StartDate: refStartDate.current.value,
          EndDate: refEndDate.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setAfterHoursSalesResponse(resObj.exception);
      setAfterHoursSalesReportValues([]);
    } else {
      setAfterHoursSalesResponse("");
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setAfterHoursSalesReportValues(spResponse.Table);
      }
    }
  };

  const convertToDollars = (_amount) => {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(_amount);
  };

  return (
    <div className="unallocatedorders-page-container">
      <div className="unallocatedorders-top-row">
        <div className="blockedorders-input-row">
          Start Date:
          <input type="date" required ref={refStartDate} />
          End Date:
          <input type="date" required ref={refEndDate} />
          <ContentButton
            id="staffmanage-edit-searchstaffname-enter"
            colour={purple}
            button="Go"
            onClick={getDailySalesPerformanceReport}
          />
        </div>
      </div>
      <div className="desktop-body-container">
        <p className="error-message">{AfterHoursSalesResponse}</p>
        {AfterHoursSalesReportValues.length > 0 ? (
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Time Block</th>
                <th>MON</th>
                <th>TUE</th>
                <th>WED</th>
                <th>THU</th>
                <th>FRI</th>
                <th>SAT</th>
                <th>SUN</th>
              </tr>
            </thead>
            <tbody>
              {AfterHoursSalesReportValues.map((row, index) => (
                <tr key={index}>
                  <td>{row.TimeBlock}</td>
                  <td>{convertToDollars(row.MON)}</td>
                  <td>{convertToDollars(row.TUE)}</td>
                  <td>{convertToDollars(row.WED)}</td>
                  <td>{convertToDollars(row.THU)}</td>
                  <td>{convertToDollars(row.FRI)}</td>
                  <td>{convertToDollars(row.SAT)}</td>
                  <td>{convertToDollars(row.SUN)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
      <div className="footer-container menu-container">
        <FooterButton button="Back" colour={purple} onClick={() => props.setPage("menu")} />
        <Link to="/">
          <FooterButton button="Home" colour={purple} />
        </Link>
      </div>
    </div>
  );
};
