import { Form, Link } from "react-router-dom";
import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { useRef, useState } from "react";
import { useId } from "react";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { generateFormJSON } from "../../functions/GenerateFormJSON";
import store from "../../store";

export const LossPreventionIncident = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [FormResponse, setFormResponse] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [JobTitleList, setJobTitleList] = useState([]);

  const Contacted_Security_ID = useId();
  const Contacted_Police_ID = useId();
  const Contacted_FireService_ID = useId();
  const Contacted_Ambulance_ID = useId();
  const Contacted_Other_ID = useId();
  const CCTVFootageAvailable_Yes = useId();
  const CCTVFootageAvailable_No = useId();
  const PhotosAvailable_Yes = useId();
  const PhotosAvailable_No = useId();
  const OffenderSuspicious_BaggyClothing = useId();
  const OffenderSuspicious_CoatJacket = useId();
  const OffenderSuspicious_FaceConcealed = useId();
  const OffenderSuspicious_Teenagers = useId();
  const OffenderSuspicious_LargeGroup = useId();
  const OffenderSuspicious_Intoxicated = useId();
  const OffenderSuspicious_MentallyUnwell = useId();
  const OffenderSuspicious_AvoidedEyeContact = useId();
  const OffenderSuspicious_Nervous = useId();
  const OffenderSuspicious_Other = useId();
  const OffenderConcealed_Hat = useId();
  const OffenderConcealed_Beanie = useId();
  const OffenderConcealed_Mask = useId();
  const OffenderConcealed_Scarf = useId();
  const OffenderConcealed_PrescriptionGlasses = useId();
  const OffenderConcealed_Sunglasses = useId();
  const OffenderConcealed_LongFringe = useId();
  const OffenderConcealed_LookDown = useId();
  const OffenderConcealed_Other = useId();
  const OffenderSuspiciousItems_Backpack = useId();
  const OffenderSuspiciousItems_EmptyPram = useId();
  const OffenderSuspiciousItems_EmptyTrolley = useId();
  const OffenderSuspiciousItems_ShoppingTrolley = useId();
  const OffenderSuspiciousItems_EmptyTrolleyWithOtherRetailerBags = useId();
  const OffenderSuspiciousItems_PramWithChild = useId();
  const OffenderSuspiciousItems_PramWithManyBags = useId();
  const OffenderSuspiciousItems_ManyBagsFromOtherRetailers = useId();
  const OffenderSuspiciousItems_Other = useId();

  const refForm = useRef();

  const handleFormSubmit = async () => {
    setFormResponse("");

    let isFormValid = refForm.current.checkValidity();
    let formData = new FormData(refForm.current);

    if (!isFormValid) {
      refForm.current.reportValidity();
    } else {
      const resObj = await runFetch(`${spURL}Handheld_Forms_Log_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
            StoreNo: storeno,
            FormType: "LP Incident Form",
            FormContentJSON: generateFormJSON(formData),
          }),
        }),
      });

      if (resObj.response === null) {
        setFormResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setFormResponse(spResponse.Table[0].OutputString);

        refForm.current.reset();
      }
    }
  };

  const getStoreList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_StoreList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(spResponse.Table.map((item) => item.StoreName));
      }
    }
  };

  const getJobTitleList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_JobTitleList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setJobTitleList(spResponse.Table.map((item) => item.JobTitle));
      }
    }
  };

  useInitialEffect(getStoreList);
  useInitialEffect(getJobTitleList);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1>
          <strong>Loss Prevention Incident</strong>
        </h1>
        <p className="error-message">{FormResponse}</p>
        <hr />
        <form
          ref={refForm}
          action={handleFormSubmit}
        >
          <h5>
            <strong>Store Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Name:*</strong>
              </p>
              <select
                name="StoreName"
                required
              >
                <option value=""></option>
                {StoreList.map((storename) => (
                  <option value={storename}>{storename}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Phone:</strong>
              </p>
              <input
                type="text"
                name="StorePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Report:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfReport"
                required
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported by:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedBy_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported to:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedTo_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Incident Information</strong>
          </h5>
          <p>The two employees listed above must complete this form together.</p>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Incident:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfIncident"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Type of Incident:*</strong>
              </p>
              <select
                required
                name="TypeOfIncident"
              >
                <option value="">--Please choose an option--</option>
                <option value="External Theft">External Theft</option>
                <option value="Internal Theft">Internal Theft</option>
                <option value="Internal Theft - Refund Fraud">Internal Theft - Refund Fraud</option>
                <option value="Internal Theft - Unauthorised Discount">
                  Internal Theft - Unauthorised Discount
                </option>
                <option value="Theft Prevented">Theft Prevented</option>
                <option value="Loss of Store Equipment">Loss of Store Equipment</option>
                <option value="Illegal Dumping">Illegal Dumping</option>
                <option value="Payment Fraud">Payment Fraud</option>
                <option value="Fraud">Fraud</option>
                <option value="Malicious Damage to Property">Malicious Damage to Property</option>
                <option value="Burglary (Store Break In)">Burglary (Store Break In)</option>
                <option value="Robbery">Robbery</option>
                <option value="Unauthorised Access to Data, Systems or Passwords">
                  Unauthorised Access to Data, Systems or Passwords
                </option>
                <option value="Cyber Attack">Cyber Attack</option>
                <option value="Armed Robbery">Armed Robbery</option>
                <option value="Assault and/or Battery">Assault and/or Battery</option>
                <option value="Arson">Arson</option>
              </select>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Contacted:*</strong>
            </p>
            <div className="popup-radio-container">
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="checkbox"
                  id={Contacted_Security_ID}
                  name="Contacted"
                  value="Security"
                />
                <label htmlFor={Contacted_Security_ID}>Security</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="checkbox"
                  id={Contacted_Police_ID}
                  name="Contacted"
                  value="Police"
                />
                <label htmlFor={Contacted_Police_ID}>Police</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="checkbox"
                  id={Contacted_FireService_ID}
                  name="Contacted"
                  value="Fire Service"
                />
                <label htmlFor={Contacted_FireService_ID}>Fire Service</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="checkbox"
                  id={Contacted_Ambulance_ID}
                  name="Contacted"
                  value="Ambulance"
                />
                <label htmlFor={Contacted_Ambulance_ID}>Ambulance</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="checkbox"
                  id={Contacted_Other_ID}
                  name="Contacted"
                  value="Other"
                />
                <label htmlFor={Contacted_Other_ID}>Other</label>
              </div>
            </div>
          </div>

          <div className="stocktake-create-input-row">
            <p>
              <strong>Police Name/s:</strong>
            </p>
            <input
              type="text"
              name="PoliceName"
            />
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Police Event Number:</strong>
              </p>
              <input
                type="text"
                name="PoliceEventNumber"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Police Phone:</strong>
              </p>
              <input
                type="text"
                name="PolicePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>CCTV Footage Available:*</strong>
              </p>
              <div className="popup-radio-container">
                <div className="printstoreaddress-settings-radio-container">
                  <input
                    type="radio"
                    id={CCTVFootageAvailable_Yes}
                    name="CCTV"
                    value="Yes"
                  />
                  <label htmlFor={CCTVFootageAvailable_Yes}>Yes</label>
                </div>
                <div className="printstoreaddress-settings-radio-container">
                  <input
                    type="radio"
                    id={CCTVFootageAvailable_No}
                    name="CCTV"
                    value="No"
                  />
                  <label htmlFor={CCTVFootageAvailable_No}>No</label>
                </div>
              </div>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Photos Available:*</strong>
              </p>
              <div className="popup-radio-container">
                <div className="printstoreaddress-settings-radio-container">
                  <input
                    type="radio"
                    id={PhotosAvailable_Yes}
                    name="Photos"
                    value="Yes"
                  />
                  <label htmlFor={PhotosAvailable_Yes}>Yes</label>
                </div>
                <div className="printstoreaddress-settings-radio-container">
                  <input
                    type="radio"
                    id={PhotosAvailable_No}
                    name="Photos"
                    value="No"
                  />
                  <label htmlFor={PhotosAvailable_No}>No</label>
                </div>
              </div>
            </div>
          </div>
          <p>
            Please save a copy of any CCTV and Photos in your stores LP incident folder on the
            stores drive.
          </p>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Incident Details:*</strong>
            </p>
            <textarea
              rows={8}
              name="IncidentDetails"
              required
            ></textarea>
          </div>
          <hr />
          <h5>
            <strong>Property Details</strong>
          </h5>
          <p>
            Only complete this section if Toymate property was stolen, damaged or recovered. Please
            note that property can include anything belonging to Toymate, and not just stock. In
            this case, place N/A for the item number and price.
          </p>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Property Recovered:</strong>
            </p>
            <select name="PropertyRecovered">
              <option value=""></option>
              <option value="No">No</option>
              <option value="Yes, Partially">Yes, Partially</option>
              <option value="Yes, Fully">Yes, Fully</option>
            </select>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>No. of Items Stolen: (Not Recovered)</strong>
              </p>
              <input
                type="text"
                name="ItemsStolen"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Total Value Stolen:*</strong>
              </p>
              <input
                type="text"
                placeholder="Enter 0 if not applicable"
                required
                name="ValueStolen"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>No. of Items Damaged:</strong>
              </p>
              <input
                type="text"
                name="ItemsDamaged"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Total Value Damaged:*</strong>
              </p>
              <input
                type="text"
                placeholder="Enter 0 if not applicable"
                required
                name="ValueDamaged"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>No. of Items Recovered:</strong>
              </p>
              <input
                type="text"
                name="ItemsRecovered"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Total Value Recovered:*</strong>
              </p>
              <input
                type="text"
                placeholder="Enter 0 if not applicable"
                name="ValueRecovered"
                required
              />
            </div>
          </div>
          <p>List the property or items that were stolen, damaged and/or recovered:</p>
          <p>(If you have more than 10 items, please use the highest valued ones.)</p>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Item Number</th>
                <th>Item Price</th>
                <th>Item Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    name="ItemDescription_1"
                    className="full-width"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="ItemNumber_1"
                    className="full-width"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="ItemPrice_1"
                    className="full-width"
                  />
                </td>
                <td>
                  <select name="ItemStatus_1">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_2"
                  />
                </td>
                <td>
                  <select name="ItemStatus_2">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_3"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_3"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_3"
                  />
                </td>
                <td>
                  <select name="ItemStatus_3">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_4"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_4"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_4"
                  />
                </td>
                <td>
                  <select name="ItemStatus_4">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_5"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_5"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_5"
                  />
                </td>
                <td>
                  <select name="ItemStatus_5">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_6"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_6"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_6"
                  />
                </td>
                <td>
                  <select name="ItemStatus_6">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_7"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_7"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_7"
                  />
                </td>
                <td>
                  <select name="ItemStatus_7">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_8"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_8"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_8"
                  />
                </td>
                <td>
                  <select name="ItemStatus_8">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_9"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_9"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_9"
                  />
                </td>
                <td>
                  <select name="ItemStatus_9">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemDescription_10"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemNumber_10"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ItemPrice_10"
                  />
                </td>
                <td>
                  <select name="ItemStatus_10">
                    <option value=""></option>
                    <option value="Stolen">Stolen</option>
                    <option value="Damaged">Damaged</option>
                    <option value="Recovered">Recovered</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h5>
            <strong>Offender Information</strong>
          </h5>
          <p>
            If the information is unknown, leave this section blank. If there are many offenders,
            focus on the three most dominant in the group.
          </p>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Offender 1</th>
                <th>Offender 2</th>
                <th>Offender 3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderName_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderName_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderName_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>
                  <select name="OffenderGender_1">
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderGender_2">
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderGender_3">
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Approximate Age:</td>
                <td>
                  <select name="OffenderAge_1">
                    <option value=""></option>
                    <option value="12 Years or Under">12 Years or Under</option>
                    <option value="13 to 15 Years">13 to 15 Years</option>
                    <option value="16 to 19 Years">16 to 19 Years</option>
                    <option value="20's">20's</option>
                    <option value="30's">30's</option>
                    <option value="40's">40's</option>
                    <option value="50's">50's</option>
                    <option value="Over 60">Over 60</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderAge_2">
                    <option value=""></option>
                    <option value="12 Years or Under">12 Years or Under</option>
                    <option value="13 to 15 Years">13 to 15 Years</option>
                    <option value="16 to 19 Years">16 to 19 Years</option>
                    <option value="20's">20's</option>
                    <option value="30's">30's</option>
                    <option value="40's">40's</option>
                    <option value="50's">50's</option>
                    <option value="Over 60">Over 60</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderAge_3">
                    <option value=""></option>
                    <option value="12 Years or Under">12 Years or Under</option>
                    <option value="13 to 15 Years">13 to 15 Years</option>
                    <option value="16 to 19 Years">16 to 19 Years</option>
                    <option value="20's">20's</option>
                    <option value="30's">30's</option>
                    <option value="40's">40's</option>
                    <option value="50's">50's</option>
                    <option value="Over 60">Over 60</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Approximate Height:</td>
                <td>
                  <select name="OffenderHeight_1">
                    <option value=""></option>
                    <option value="Under 160 cm">Under 160 cm</option>
                    <option value="160-165 cm">160-165 cm</option>
                    <option value="166-170 cm">166-170 cm</option>
                    <option value="171-175 cm">171-175 cm</option>
                    <option value="176-180 cm">176-180 cm</option>
                    <option value="181-186 cm">181-185 cm</option>
                    <option value="186-190 cm">186-190 cm</option>
                    <option value="Over 190 cm">Over 190 cm</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderHeight_2">
                    <option value=""></option>
                    <option value="Under 160 cm">Under 160 cm</option>
                    <option value="160-165 cm">160-165 cm</option>
                    <option value="166-170 cm">166-170 cm</option>
                    <option value="171-175 cm">171-175 cm</option>
                    <option value="176-180 cm">176-180 cm</option>
                    <option value="181-186 cm">181-185 cm</option>
                    <option value="186-190 cm">186-190 cm</option>
                    <option value="Over 190 cm">Over 190 cm</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderHeight_3">
                    <option value=""></option>
                    <option value="Under 160 cm">Under 160 cm</option>
                    <option value="160-165 cm">160-165 cm</option>
                    <option value="166-170 cm">166-170 cm</option>
                    <option value="171-175 cm">171-175 cm</option>
                    <option value="176-180 cm">176-180 cm</option>
                    <option value="181-186 cm">181-185 cm</option>
                    <option value="186-190 cm">186-190 cm</option>
                    <option value="Over 190 cm">Over 190 cm</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Build:</td>
                <td>
                  <select name="OffenderBuild_1">
                    <option value=""></option>
                    <option value="Slim or Small">Slim or Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Overweight">Overweight</option>
                    <option value="Obese">Obese</option>
                    <option value="Morbidly Obese">Morbidly Obese</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderBuild_2">
                    <option value=""></option>
                    <option value="Slim or Small">Slim or Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Overweight">Overweight</option>
                    <option value="Obese">Obese</option>
                    <option value="Morbidly Obese">Morbidly Obese</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderBuild_3">
                    <option value=""></option>
                    <option value="Slim or Small">Slim or Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Overweight">Overweight</option>
                    <option value="Obese">Obese</option>
                    <option value="Morbidly Obese">Morbidly Obese</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Eye Colour:</td>
                <td>
                  <select name="OffenderEyeColour_1">
                    <option value=""></option>
                    <option value="Light Blue">Light Blue</option>
                    <option value="Blue">Blue</option>
                    <option value="Blue-Grey">Blue-Grey</option>
                    <option value="Green">Green</option>
                    <option value="Hazel">Hazel</option>
                    <option value="Light Brown">Light Brown</option>
                    <option value="Brown">Brown</option>
                    <option value="Dark Brown">Dark Brown</option>
                    <option value="Amber">Amber</option>
                    <option value="Red">Red</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderEyeColour_2">
                    <option value=""></option>
                    <option value="Light Blue">Light Blue</option>
                    <option value="Blue">Blue</option>
                    <option value="Blue-Grey">Blue-Grey</option>
                    <option value="Green">Green</option>
                    <option value="Hazel">Hazel</option>
                    <option value="Light Brown">Light Brown</option>
                    <option value="Brown">Brown</option>
                    <option value="Dark Brown">Dark Brown</option>
                    <option value="Amber">Amber</option>
                    <option value="Red">Red</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
                <td>
                  <select name="OffenderEyeColour_3">
                    <option value=""></option>
                    <option value="Light Blue">Light Blue</option>
                    <option value="Blue">Blue</option>
                    <option value="Blue-Grey">Blue-Grey</option>
                    <option value="Green">Green</option>
                    <option value="Hazel">Hazel</option>
                    <option value="Light Brown">Light Brown</option>
                    <option value="Brown">Brown</option>
                    <option value="Dark Brown">Dark Brown</option>
                    <option value="Amber">Amber</option>
                    <option value="Red">Red</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Hair Colour:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairColour_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairColour_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairColour_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Hair Length and Style:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairStyle_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairStyle_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderHairStyle_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Race or Nationality:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderRace_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderRace_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderRace_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Clothing:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderClothing_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderClothing_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderClothing_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Clothing Accessories:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderAccessories_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderAccessories_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderAccessories_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Type of Footwear:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFootwear_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFootwear_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFootwear_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Distinguishing Features:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFeatures_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFeatures_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderFeatures_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Vehicle Registration No:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleRegistration_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleRegistration_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleRegistration_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Vehicle Make/Model:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleModel_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleModel_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleModel_3"
                  />
                </td>
              </tr>
              <tr>
                <td>Vehicle Colour:</td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleColour_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleColour_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="OffenderVehicleColour_3"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h5>
            <strong>Witness Information</strong>
          </h5>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Job Title</th>
                <th>Contact Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_1"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_1">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_1"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_2"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_2">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_2"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessName_3"
                  />
                </td>
                <td>
                  <select name="WitnessJobTitle_3">
                    <option value=""></option>
                    {JobTitleList.map((jobtitle) => (
                      <option value={jobtitle}>{jobtitle}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="WitnessContact_3"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h5>
            <strong>Incident Analysis</strong>
          </h5>
          <p>If any part of this section is not applicable, leave the cell or box blank.</p>

          <div className="input-row">
            <p>
              <strong>Did you find the offender/s appearance suspicious?</strong>
            </p>
            <select name="OffenderSuspicious">
              <option value=""> </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <p>
            <strong>
              If yes, what made them look suspicious? Please select all that are applicable.
            </strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_BaggyClothing}
                name="OffenderSuspicious_Reason"
                value="Baggy clothing was worn"
              />
              <label htmlFor={OffenderSuspicious_BaggyClothing}>Baggy clothing was worn</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_CoatJacket}
                name="OffenderSuspicious_Reason"
                value="Coat or jacket was worn"
              />
              <label htmlFor={OffenderSuspicious_CoatJacket}>Coat or jacket was worn</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_FaceConcealed}
                name="OffenderSuspicious_Reason"
                value="Face was concealed"
              />
              <label htmlFor={OffenderSuspicious_FaceConcealed}>Face was concealed</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_Teenagers}
                name="OffenderSuspicious_Reason"
                value="Many teenagers entered at once"
              />
              <label htmlFor={OffenderSuspicious_Teenagers}>Many teenagers entered at once</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_LargeGroup}
                name="OffenderSuspicious_Reason"
                value="Entered the store in a large group"
              />
              <label htmlFor={OffenderSuspicious_LargeGroup}>
                Entered the store in a large group
              </label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_Intoxicated}
                name="OffenderSuspicious_Reason"
                value="Appeared intoxicated by alcohol or drugs"
              />
              <label htmlFor={OffenderSuspicious_Intoxicated}>
                Appeared intoxicated by alcohol or drugs
              </label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_MentallyUnwell}
                name="OffenderSuspicious_Reason"
                value="Appeared mentally unwell"
              />
              <label htmlFor={OffenderSuspicious_MentallyUnwell}>Appeared mentally unwell</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_AvoidedEyeContact}
                name="OffenderSuspicious_Reason"
                value="Avoided eye contact"
              />
              <label htmlFor={OffenderSuspicious_AvoidedEyeContact}>Avoided eye contact</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_Nervous}
                name="OffenderSuspicious_Reason"
                value="Looked nervous or anxious"
              />
              <label htmlFor={OffenderSuspicious_Nervous}>Looked nervous or anxious</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspicious_Other}
                name="OffenderSuspicious_Reason"
                value="Other or N/A"
              />
              <label htmlFor={OffenderSuspicious_Other}>Other or N/A</label>
            </div>
          </div>
          <div className="input-row">
            <p>
              <strong>If option is not listed, please specify:</strong>
            </p>
            <textarea
              rows={5}
              name="OffenderSuspicious_OtherReason"
            ></textarea>
          </div>
          <p>
            <strong>
              If the offenders concealed their face, how did they do it? Please select all that are
              applicable.
            </strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Hat}
                name="OffenderConcealed_Items"
                value="Hat or cap"
              />
              <label htmlFor={OffenderConcealed_Hat}>Hat or cap</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Beanie}
                name="OffenderConcealed_Items"
                value="Beanie"
              />
              <label htmlFor={OffenderConcealed_Beanie}>Beanie</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Mask}
                name="OffenderConcealed_Items"
                value="Mask"
              />
              <label htmlFor={OffenderConcealed_Mask}>Mask</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Scarf}
                name="OffenderConcealed_Items"
                value="Scarf"
              />
              <label htmlFor={OffenderConcealed_Scarf}>Scarf</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_PrescriptionGlasses}
                name="OffenderConcealed_Items"
                value="Prescription glasses"
              />
              <label htmlFor={OffenderConcealed_PrescriptionGlasses}>Prescription glasses</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Sunglasses}
                name="OffenderConcealed_Items"
                value="Sunglasses"
              />
              <label htmlFor={OffenderConcealed_Sunglasses}>Sunglasses</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_LongFringe}
                name="OffenderConcealed_Items"
                value="Long fringe or hair"
              />
              <label htmlFor={OffenderConcealed_LongFringe}>Long fringe or hair</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_LookDown}
                name="OffenderConcealed_Items"
                value="Always looked down"
              />
              <label htmlFor={OffenderConcealed_LookDown}>Always looked down</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderConcealed_Other}
                name="OffenderConcealed_Items"
                value="Other or N/A"
              />
              <label htmlFor={OffenderConcealed_Other}>Other or N/A</label>
            </div>
          </div>
          <div className="input-row">
            <p>
              <strong>If option is not listed, please specify:</strong>
            </p>
            <textarea
              rows={5}
              name="OffenderConcealed_OtherItems"
            ></textarea>
          </div>
          <p>
            <strong>
              Did they enter the store with anything that was suspicious? Please select all that are
              applicable.
            </strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_Backpack}
                name="OffenderSuspicious_Items"
                value="Backpack"
              />
              <label htmlFor={OffenderSuspiciousItems_Backpack}>Backpack</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_EmptyPram}
                name="OffenderSuspicious_Items"
                value="Empty Pram/Stroller"
              />
              <label htmlFor={OffenderSuspiciousItems_EmptyPram}>Empty Pram/Stroller</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_EmptyTrolley}
                name="OffenderSuspicious_Items"
                value="Empty trolley with boxes"
              />
              <label htmlFor={OffenderSuspiciousItems_EmptyTrolley}>Empty trolley with boxes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_ShoppingTrolley}
                name="OffenderSuspicious_Items"
                value="Shopping trolley from another retailer"
              />
              <label htmlFor={OffenderSuspiciousItems_ShoppingTrolley}>
                Shopping trolley from another retailer
              </label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_EmptyTrolleyWithOtherRetailerBags}
                name="OffenderSuspicious_Items"
                value="Empty trolley with bags from other retailers"
              />
              <label htmlFor={OffenderSuspiciousItems_EmptyTrolleyWithOtherRetailerBags}>
                Empty trolley with bags from other retailers
              </label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_PramWithChild}
                name="OffenderSuspicious_Items"
                value="Pram/Stroller with a child"
              />
              <label htmlFor={OffenderSuspiciousItems_PramWithChild}>
                Pram/Stroller with a child
              </label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_PramWithManyBags}
                name="OffenderSuspicious_Items"
                value="Pram/Stroller with many bags"
              />
              <label htmlFor={OffenderSuspiciousItems_PramWithManyBags}>
                Pram/Stroller with many bags
              </label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_ManyBagsFromOtherRetailers}
                name="OffenderSuspicious_Items"
                value="Many bags from retailers"
              />
              <label htmlFor={OffenderSuspiciousItems_ManyBagsFromOtherRetailers}>
                Many bags from retailers
              </label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={OffenderSuspiciousItems_Other}
                name="OffenderSuspicious_Items"
                value="Other"
              />
              <label htmlFor={OffenderSuspiciousItems_Other}>Other or N/A</label>
            </div>
          </div>
          <div className="input-row">
            <p>
              <strong>If option is not listed, please specify:</strong>
            </p>
            <textarea
              rows={5}
              name="OffenderSuspicious_OtherItems"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>What immediate action was taken to help stop the offender?</strong>
            </p>
            <textarea
              rows={5}
              name="ActionsTaken"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If offender was not stopped, what went wrong?</strong>
            </p>
            <textarea
              rows={5}
              name="WhatWentWrong"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>
                Even if the offender was stopped, how could this incident have been prevented?
              </strong>
            </p>
            <textarea
              rows={5}
              name="Prevention"
            ></textarea>
          </div>
          <hr />
          <h5>
            <strong>Action Plan</strong>
          </h5>
          <p>
            After identifying the issues, take corrective action by completing the plan below. If
            any assistance is required, contact the Loss Prevention manager. (You do not need to
            complete this section if the incident was prevented.)
          </p>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Goal</th>
                <th>Actions to Achieve</th>
                <th>When</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_Goal_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_ActionsToAchieve_1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_When_1"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_Goal_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_ActionsToAchieve_2"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_When_2"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_Goal_3"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_ActionsToAchieve_3"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="full-width"
                    name="ActionPlan_When_3"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div className="footer-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={purple}
          onClick={handleFormSubmit}
        />
      </div>
    </div>
  );
};
