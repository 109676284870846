import { useState } from "react";
import store from "../../store";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { spURL, yellow } from "../../App";
import { Menu } from "./Menu";
import { Create } from "./Create";
import { Add } from "./Add";

export const TheftReceiptBody = (props) => {
  const [page, setPage] = useState("menu");

  const emptyTheftReceipt = {
    TheftReceiptID: 0,
    TheftReceiptName: "",
  };
  const [SelectedTheftReceipt, setSelectedTheftReceipt] = useState(emptyTheftReceipt);

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      SelectedTheftReceipt={SelectedTheftReceipt}
      setSelectedTheftReceipt={setSelectedTheftReceipt}
    />
  );

  const createPage = (
    <Create
      page={page}
      setPage={setPage}
      setSelectedTheftReceipt={setSelectedTheftReceipt}
    />
  );

  const addPage = (
    <Add
      page={page}
      setPage={setPage}
      SelectedTheftReceipt={SelectedTheftReceipt}
      setSelectedTheftReceipt={setSelectedTheftReceipt}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "create":
        return createPage;
      case "add":
        return addPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
