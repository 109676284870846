import { useEffect } from "react";

export const useInitialEffect = (_func) => {
  useEffect(() => {
    const controller = new AbortController();
    _func(controller.signal);

    return () => controller.abort();
  }, []);
};
