import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { CapitaliseString } from "../../functions/CapitaliseString";

export const PopupPickQty = (props) => {
  const [userid, ,] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");

  const refQty = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    refQty.current.value = 1;
    refQty.current.select();
  };

  const handleClick_Action = async (_action) => {
    const resObj = await runFetch(
      `${spURL}Handheld_WarehouseOrdering_PickOrders_${CapitaliseString(_action)}Qty`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
            SKU: props.SelectedItem.ItemNo,
            Qty: refQty.current.value,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        await props.pageLoad();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="popup-container">
            <h4>
              <strong>Pick Item</strong>
            </h4>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SKU</strong>
              </p>
              <p>{props.SelectedItem.ItemNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Pick Qty:</strong>
              </p>
              <input
                type="text"
                ref={refQty}
              />
            </div>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={() => handleClick_Action("RESET")}
            >
              <strong>Reset</strong>
            </button>
            <button
              className="popup-button"
              onClick={() => handleClick_Action("UPDATE")}
            >
              <strong>Update</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
