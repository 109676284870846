import { useRef, useState } from "react";
import store from "../../store";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { spURL, yellow } from "../../App";
import { Link } from "react-router-dom";
import { FooterButton } from "../../components/HomepageButton";
import { convertDateTimeJSToSQL } from "../../functions/ConvertDateTimeJSToSQL";

export const Create = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");
  const [storename, ,] = store.useState("storename");

  const [TheftReceiptResponse, setTheftReceiptResponse] = useState("");

  const refReceiptDate = useRef();

  const handleClick_Create = async () => {
    const resObj = await runFetch(`${spURL}Handheld_TheftReceipt_Receipt_Create`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          UserID: userid,
          Date: convertDateTimeJSToSQL(refReceiptDate.current.value),
        }),
      }),
    });

    if (resObj.response === null) {
      setTheftReceiptResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setSelectedTheftReceipt(spResponse.Table[0]);
        props.setPage("add");
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <p>{storename}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Date</strong>
          </p>
          <input
            type="datetime-local"
            required
            ref={refReceiptDate}
          />
        </div>

        <p className="error-message">{TheftReceiptResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={yellow}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
