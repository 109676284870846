import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Reports = (props) => {
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [IncludeReports, setIncludeReports] = useState([
    "StockTakeReport",
    "BaySummary",
    "ItemSummary",
    "BayDetails",
    "BaysNotCounted",
    "Check",
  ]);

  useEffect(() => {
    setStockTakeResponse("");
  }, []);

  const handleCheck_IncludeReports = (e) => {
    const _checked = e.target.checked;
    const _report = e.target.value;

    if (_checked) {
      setIncludeReports([...IncludeReports, _report]);
    } else {
      setIncludeReports(IncludeReports.filter((item) => item !== _report));
    }
  };

  const handleClick = async () => {
    setStockTakeResponse("Please wait while the report is being loading...");

    const resObj = await runFetch(`${spURL}Stocktake_Export_Report`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: props.FromPage.StoreNo,
          StockTake: props.CurrentStockTake,
          IncludeReports: IncludeReports.toString(),
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        let excelSheets = spResponse.Table.map((item, index) => ({
          SheetName: item.Report,
          ReferToTable: `Table${index + 1}`,
        }));

        const workbook = new ExcelJS.Workbook();

        excelSheets.forEach((report) => {
          var stocktakereportsheet = workbook.addWorksheet(report.SheetName, {
            views: [{ activeCell: "A1", showGridLines: true }],
          });

          let stocktakereportArray = spResponse[report.ReferToTable];

          if (stocktakereportArray !== undefined && stocktakereportArray.length > 0) {
            let firstRowObj = stocktakereportArray[0];

            // set header information
            stocktakereportsheet.columns = Object.keys(firstRowObj).map((key) => ({
              header: key,
              key: key,
            }));

            if (stocktakereportsheet !== undefined) {
              stocktakereportsheet.addRows(stocktakereportArray, "i");
            }

            switch (report.SheetName) {
              case "StockTakeReport":
                stocktakereportsheet.getColumn("SnapShotQuantity").numFmt = "#,##0";
                stocktakereportsheet.getColumn("SOO").numFmt = "#,##0";
                stocktakereportsheet.getColumn("SOT").numFmt = "#,##0";
                stocktakereportsheet.getColumn("Counted").numFmt = "#,##0";
                stocktakereportsheet.getColumn("Variance").numFmt = "#,##0";

                stocktakereportsheet.getColumn("Unit Cost").numFmt = "$#,##0.00";
                stocktakereportsheet.getColumn("Unit Price").numFmt = "$#,##0.00";
                stocktakereportsheet.getColumn("Variance Cost").numFmt = "$#,##0.00";

                break;
              case "BaySummary":
                stocktakereportsheet.getColumn("Items").numFmt = "#,##0";
                stocktakereportsheet.getColumn("BayItemCount").numFmt = "#,##0";
                stocktakereportsheet.getColumn("QuantityScanned").numFmt = "#,##0";
                break;
              case "ItemSummary":
                stocktakereportsheet.getColumn("SnapShotQuantity").numFmt = "#,##0";
                stocktakereportsheet.getColumn("Counted").numFmt = "#,##0";
                break;
              case "BayDetails":
                stocktakereportsheet.getColumn("Counted").numFmt = "#,##0";
                stocktakereportsheet.getColumn("Re Count").numFmt = "#,##0";
                break;
              case "BaysNotCounted":
                break;
              case "Check":
                stocktakereportsheet.getColumn("Quantity Scanned").numFmt = "#,##0";
                break;
              case "Sales":
                stocktakereportsheet.getColumn("SnapShotQuantity").numFmt = "#,##0";
                stocktakereportsheet.getColumn("Counted").numFmt = "#,##0";
                break;
              default:
                break;
            }

            stocktakereportsheet.autoFilter = {
              from: {
                row: 1,
                column: 1,
              },
              to: {
                row: stocktakereportArray.length,
                // column: 30,
                column: Object.keys(stocktakereportArray[0]).length,
              },
            };
            autoWidth(stocktakereportsheet);
          }
        });

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          FileSaver.saveAs(blob, "StocktakeReport.xlsx");
        });

        setStockTakeResponse("Report is successfully downloaded.");
      } else {
        setStockTakeResponse("Report is empty.");
      }
    }
  };
  // };

  const autoWidth = (worksheet, minimalWidth = 10) => {
    worksheet.columns.forEach((column) => {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        maxColumnLength = Math.max(
          maxColumnLength,
          minimalWidth,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = maxColumnLength + 2;
    });
  };

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
          <hr />
          <h5>
            <strong>Select reports:</strong>
          </h5>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="stocktakereport"
              className="btnHover no-highlight"
            >
              <strong>StockTake Report</strong>
            </label>
            <input
              type="checkbox"
              value="StockTakeReport"
              id="stocktakereport"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="baysummary"
              className="btnHover no-highlight"
            >
              <strong>Bay Summary</strong>
            </label>
            <input
              type="checkbox"
              value="BaySummary"
              id="baysummary"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="itemsummary"
              className="btnHover no-highlight"
            >
              <strong>Item Summary</strong>
            </label>
            <input
              type="checkbox"
              value="ItemSummary"
              id="itemsummary"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="baydetails"
              className="btnHover no-highlight"
            >
              <strong>Bay Details</strong>
            </label>
            <input
              type="checkbox"
              value="BayDetails"
              id="baydetails"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="baysnotcounted"
              className="btnHover no-highlight"
            >
              <strong>Bays Not Counted</strong>
            </label>
            <input
              type="checkbox"
              value="BaysNotCounted"
              id="baysnotcounted"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <label
              htmlFor="check"
              className="btnHover no-highlight"
            >
              <strong>Check</strong>
            </label>
            <input
              type="checkbox"
              value="Check"
              id="check"
              className="btnHover no-highlight"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            Download
          </button>
        </div>

        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={red}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
