import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PopupAddToCart } from "./PopupAddToCart";

export const PopupOrder = (props) => {
  const [userid, ,] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");

  const emptyItem = {
    SKU: 0,
    Category: "",
    Item: "",
    Qty: 0,
  };
  const [SelectedItem, setSelectedItem] = useState(emptyItem);

  const [showEditCart, setShowEditCart] = useState(false);

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
    setSelectedItem(emptyItem);
  };

  const handleShow = () => {
    setPopupResponse("");
    setSelectedItem(emptyItem);
    props.getCart();
  };

  const handleClick_EditItem = async () => {};

  const handleClick_DeleteItem = async (_cartlineid) => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_Cart_Item_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          CartID: props.CartID,
          CartLineID: _cartlineid,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setPopupResponse(spResponse.Table[0].OutputString);
        props.getCart();
      }
    }
  };

  const handleClick_Order = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_Cart_Confirm`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          CartID: props.CartID,
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h1>
              <strong>Confirm to order?</strong>
            </h1>
            <p className="error-message">{PopupResponse}</p>
            <table className="itemdetails-table no-highlight">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.CartItems.length > 0 &&
                  props.CartItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.SKU}</td>
                      <td>
                        <strong>{item.Description}</strong>
                        <br />
                        {item.Category}
                      </td>
                      <td>{item.Qty}</td>
                      <td>
                        <FontAwesomeIcon
                          className="btnHover"
                          icon={solid("pen-to-square")}
                          onClick={() => {
                            setPopupResponse("");
                            setSelectedItem({
                              SKU: item.SKU,
                              Category: item.Category,
                              Item: item.Description,
                              Qty: item.Qty,
                            });
                            setShowEditCart(true);
                          }}
                        />
                        <FontAwesomeIcon
                          className="btnHover color-red"
                          icon={solid("xmark")}
                          onClick={() => handleClick_DeleteItem(item.CartLineID)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <PopupAddToCart
              show={showEditCart}
              setShow={setShowEditCart}
              CartID={props.CartID}
              SelectedItem={SelectedItem}
              setWarehouseOrderingResponse={setPopupResponse}
              getCart={props.getCart}
              Type="Edit"
            />
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Order}
            >
              <strong>Order</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
