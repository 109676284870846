import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const PopupBayHistory = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");
  const [HistoryTable, setHistoryTable] = useState([]);

  const [sortColumn, setSortColumn] = useState("Time");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortIcon, setSortIcon] = useState(solid("sort"));

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_Bays_BayHistory`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: props.FromPage.StoreNo,
          StockTake: props.CurrentStockTake,
          BayNo: props.SelectedBay.BayNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table.length > 0) {
        setHistoryTable(
          spResponse.Table.map((item) => ({
            Item: item.Item,
            Description: item.Description,
            Counted: item.Counted,
            Time: item.Time,
          }))
        );
      } else {
        setPopupResponse("No items scanned.");
      }
    }
  };

  const handleClick_HeaderRow = (_colname) => {
    const currentSortOrder = sortOrder;

    if (_colname === sortColumn) {
      if (currentSortOrder === "ASC") {
        setSortOrder("DESC");
      } else {
        setSortOrder("ASC");
      }
    } else {
      setSortOrder("ASC");
    }

    setSortColumn(_colname);
  };

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <table className="itemdetails-table">
              <thead>
                <tr className="btnHover no-highlight">
                  <th onClick={() => handleClick_HeaderRow("Item")}>
                    <strong>Item</strong>
                    {sortColumn === "Item" && <FontAwesomeIcon icon={sortIcon} />}
                  </th>
                  <th onClick={() => handleClick_HeaderRow("Description")}>
                    <strong>Description</strong>
                    {sortColumn === "Description" && <FontAwesomeIcon icon={sortIcon} />}
                  </th>
                  <th onClick={() => handleClick_HeaderRow("Counted")}>
                    <strong>Qty</strong>
                    {sortColumn === "Counted" && <FontAwesomeIcon icon={sortIcon} />}
                  </th>
                  <th onClick={() => handleClick_HeaderRow("Time")}>
                    <strong>Count Time</strong>
                    {sortColumn === "Time" && <FontAwesomeIcon icon={sortIcon} />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {HistoryTable.sort((a, b) => {
                  switch (sortColumn) {
                    case "Counted":
                      return sortOrder === "ASC"
                        ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
                        : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
                    default:
                      return sortOrder === "ASC"
                        ? a[sortColumn].localeCompare(b[sortColumn])
                        : b[sortColumn].localeCompare(a[sortColumn]);
                  }
                }).map((item, index) => (
                  <tr key={index}>
                    <td>{item.Item}</td>
                    <td>{item.Description}</td>
                    <td>{item.Counted}</td>
                    <td>{new Date(item.Time).toLocaleString("en-AU")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
