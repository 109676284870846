import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { CapitaliseString } from "../../functions/CapitaliseString";

export const PopupItem = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const emptyItemDetails = {
    ItemNo: "",
    Description: "",
    Department: "",
    Category: "",
    SubCategory: "",
    ImageURL: "",
  };
  const [ItemDetails, setItemDetails] = useState(emptyItemDetails);

  const refQty = useRef();

  const handleClose = () => {
    setPopupResponse("");

    props.cleanBarcode();
    props.getReceiptLines();

    props.setShow(false);

    setItemDetails(emptyItemDetails);
    refQty.current.value = "";
  };

  const getItemDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_TheftReceipt_ItemDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: props.ScannedBarcode,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemDetails({
          ItemNo: spResponse.Table[0].ItemNo,
          Description: spResponse.Table[0].Description,
          Department: spResponse.Table[0].Department,
          Category: spResponse.Table[0].Category,
          SubCategory: spResponse.Table[0].SubCategory,
          ImageURL: spResponse.Table[0].ImageURL,
        });
      }
    }
  };

  const handleShow = () => {
    getItemDetails();
    refQty.current.value = props.Action === "EDIT" ? props.SelectedItemQty : 1;
    refQty.current.select();
  };

  const handleClick_Action = async (_action) => {
    const resObj = await runFetch(
      `${spURL}Handheld_TheftReceipt_${CapitaliseString(_action)}Item`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            TheftReceiptID: props.SelectedTheftReceipt.TheftReceiptID,
            ItemNo: ItemDetails.ItemNo,
            Qty: refQty.current.value,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setTheftReceiptResponse(spResponse.Table[0].OutputString);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h4>
              <strong>{CapitaliseString(props.Action)} Item</strong>
            </h4>
            <div className="stocktake-create-input-row">
              <p>
                <strong>ItemNo:</strong>
              </p>
              <p>{ItemDetails.ItemNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <p>{ItemDetails.Description}</p>
            </div>
            <div>
              <img src={ItemDetails.ImageURL} />
            </div>
            <div>
              <p>{ItemDetails.Department}</p>
              <p>
                {">"}
                {ItemDetails.Category}
              </p>
              <p>
                {">>"}
                {ItemDetails.SubCategory}
              </p>
            </div>
            <br />
            <div className="stocktake-create-input-row">
              <p>
                <strong>Qty:</strong>
              </p>
              <input
                type="text"
                ref={refQty}
              />
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            {props.Action === "EDIT" && (
              <button
                className="popup-button"
                onClick={() => handleClick_Action("Delete")}
              >
                <strong>Delete</strong>
              </button>
            )}
            <button
              className="popup-button"
              onClick={() => handleClick_Action(props.Action)}
            >
              <strong>{CapitaliseString(props.Action)}</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
