import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAccessData, orange } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import store from "../../store";

export const Menu = (props) => {
  const [userid, ,] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState("");

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("order")}
            button="O"
            colour={orange}
            title="Order"
          />
          {Access.WarehouseOrderingAdmin && (
            <MenuButton
              onClick={() => props.setPage("admin")}
              button="A"
              colour={orange}
              title="Admin"
            />
          )}
          {Access.WarehouseOrderingPick && (
            <MenuButton
              onClick={() => props.setPage("pick-header")}
              button="P"
              colour={orange}
              title="Pick"
            />
          )}
          {Access.WarehouseOrderingPickManager && (
            <MenuButton
              onClick={() => props.setPage("pick-manager")}
              button="PM"
              colour={orange}
              title="Pick Manager"
            />
          )}
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
