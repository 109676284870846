import { useRef, useState } from "react";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { useId } from "react";
import { generateFormJSON } from "../../functions/GenerateFormJSON";
import store from "../../store";

export const MonthlySafetyAudit = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [FormResponse, setFormResponse] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [JobTitleList, setJobTitleList] = useState([]);

  const LastMonthResolved_Yes = useId();
  const LastMonthResolved_No = useId();
  const LastMonthResolved_NA = useId();
  const EmergencyExitWork_Yes = useId();
  const EmergencyExitWork_No = useId();
  const EmergencyExitWork_NA = useId();
  const EmergencyExitOpen_Yes = useId();
  const EmergencyExitOpen_No = useId();
  const EmergencyExitOpen_NA = useId();
  const LaddersSafe_Yes = useId();
  const LaddersSafe_No = useId();
  const LaddersSafe_NA = useId();
  const TopShelvesSecured_Yes = useId();
  const TopShelvesSecured_No = useId();
  const TopShelvesSecured_NA = useId();
  const TrippingHazards_Yes = useId();
  const TrippingHazards_No = useId();
  const TrippingHazards_NA = useId();
  const OverstocksSafelyStacked_Yes = useId();
  const OverstocksSafelyStacked_No = useId();
  const OverstocksSafelyStacked_NA = useId();
  const SharpEdgesOnFixtures_Yes = useId();
  const SharpEdgesOnFixtures_No = useId();
  const SharpEdgesOnFixtures_NA = useId();
  const FittingsAreaNeat_Yes = useId();
  const FittingsAreaNeat_No = useId();
  const FittingsAreaNeat_NA = useId();
  const CleaningRoomLocked_Yes = useId();
  const CleaningRoomLocked_No = useId();
  const CleaningRoomLocked_NA = useId();
  const FirstAidKitLocation_Yes = useId();
  const FirstAidKitLocation_No = useId();
  const FirstAidKitLocation_NA = useId();
  const FirstAidKitContent_Yes = useId();
  const FirstAidKitContent_No = useId();
  const FirstAidKitContent_NA = useId();
  const HiVisVestAvailable_Yes = useId();
  const HiVisVestAvailable_No = useId();
  const HiVisVestAvailable_NA = useId();
  const KeyRegisterUpToDate_Yes = useId();
  const KeyRegisterUpToDate_No = useId();
  const MonitoringCalloutListUpdated_Yes = useId();
  const MonitoringCalloutListUpdated_No = useId();

  const refForm = useRef();

  const handleFormSubmit = async () => {
    setFormResponse("");

    let isFormValid = refForm.current.checkValidity();
    let formData = new FormData(refForm.current);

    if (!isFormValid) {
      refForm.current.reportValidity();
    } else {
      const resObj = await runFetch(`${spURL}Handheld_Forms_Log_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
            StoreNo: storeno,
            FormType: "Transport Incident Form",
            FormContentJSON: generateFormJSON(formData),
          }),
        }),
      });

      if (resObj.response === null) {
        setFormResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setFormResponse(spResponse.Table[0].OutputString);

        refForm.current.reset();
      }
    }
  };

  const getStoreList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_StoreList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(spResponse.Table.map((item) => item.StoreName));
      }
    }
  };

  const getJobTitleList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_JobTitleList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setJobTitleList(spResponse.Table.map((item) => item.JobTitle));
      }
    }
  };

  useInitialEffect(getStoreList);
  useInitialEffect(getJobTitleList);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1>
          <strong>Monthly Safety Audit</strong>
        </h1>
        <hr />
        <p className="error-message">{FormResponse}</p>
        <form ref={refForm}>
          <h5>
            <strong>Store Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Name:*</strong>
              </p>
              <select
                name="StoreName"
                required
              >
                <option value=""></option>
                {StoreList.map((storename) => (
                  <option value={storename}>{storename}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Phone:</strong>
              </p>
              <input
                type="text"
                name="StorePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Report:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfReport"
                required
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported Completed by:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedBy_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Incident Information</strong>
          </h5>
          <p>
            <strong>Have all issues from last month's audit been resolved?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LastMonthResolved_Yes}
                name="LastMonthResolved"
                value="Yes"
              />
              <label htmlFor={LastMonthResolved_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LastMonthResolved_No}
                name="LastMonthResolved"
                value="No"
                required
              />
              <label htmlFor={LastMonthResolved_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LastMonthResolved_NA}
                name="LastMonthResolved"
                value="N/A"
                required
              />
              <label htmlFor={LastMonthResolved_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>What is still outstanding and what follow up have you done?</strong>
            </p>
            <textarea
              rows={5}
              name="OutstandingDescription"
            ></textarea>
          </div>

          <hr />
          <p>
            <strong>Do Emergency Exits Work?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitWork_Yes}
                name="EmergencyExitWork"
                value="Yes"
                required
              />
              <label htmlFor={EmergencyExitWork_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitWork_No}
                name="EmergencyExitWork"
                value="No"
                required
              />
              <label htmlFor={EmergencyExitWork_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitWork_NA}
                name="EmergencyExitWork"
                value="N/A"
                required
              />
              <label htmlFor={EmergencyExitWork_NA}></label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="EmergencyExitWorkComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="EmergencyExitWorkActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Do Emergency Exits Open?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitOpen_Yes}
                name="EmergencyExitOpen"
                value="Yes"
                required
              />
              <label htmlFor={EmergencyExitOpen_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitOpen_No}
                name="EmergencyExitOpen"
                value="No"
                required
              />
              <label htmlFor={EmergencyExitOpen_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={EmergencyExitOpen_NA}
                name="EmergencyExitOpen"
                value="N/A"
                required
              />
              <label htmlFor={EmergencyExitOpen_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="EmergencyExitOpenComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="EmergencyExitOpenActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Are Ladders Safe?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LaddersSafe_Yes}
                name="LaddersSafe"
                value="Yes"
                required
              />
              <label htmlFor={LaddersSafe_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LaddersSafe_No}
                name="LaddersSafe"
                value="No"
                required
              />
              <label htmlFor={LaddersSafe_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={LaddersSafe_NA}
                name="LaddersSafe"
                value="N/A"
                required
              />
              <label htmlFor={LaddersSafe_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="LaddersSafeComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="LaddersSafeActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Are Top Shelves Safely Secured?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TopShelvesSecured_Yes}
                name="TopShelvesSecured"
                value="Yes"
                required
              />
              <label htmlFor={TopShelvesSecured_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TopShelvesSecured_No}
                name="TopShelvesSecured"
                value="No"
                required
              />
              <label htmlFor={TopShelvesSecured_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TopShelvesSecured_NA}
                name="TopShelvesSecured"
                value="N/A"
                required
              />
              <label htmlFor={TopShelvesSecured_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="TopShelvesSecuredComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="TopShelvesSecuredActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Are There Any Tripping Hazards?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TrippingHazards_Yes}
                name="TrippingHazards"
                value="Yes"
                required
              />
              <label htmlFor={TrippingHazards_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TrippingHazards_No}
                name="TrippingHazards"
                value="No"
                required
              />
              <label htmlFor={TrippingHazards_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={TrippingHazards_NA}
                name="TrippingHazards"
                value="N/A"
                required
              />
              <label htmlFor={TrippingHazards_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="TrippingHazardsComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="TrippingHazardsActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Are The Overstocks Safely Stacked?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={OverstocksSafelyStacked_Yes}
                name="OverstocksSafelyStacked"
                value="Yes"
                required
              />
              <label htmlFor={OverstocksSafelyStacked_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={OverstocksSafelyStacked_No}
                name="OverstocksSafelyStacked"
                value="No"
                required
              />
              <label htmlFor={OverstocksSafelyStacked_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={OverstocksSafelyStacked_NA}
                name="OverstocksSafelyStacked"
                value="N/A"
                required
              />
              <label htmlFor={OverstocksSafelyStacked_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="OverstocksSafelyStackedComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="OverstocksSafelyStackedActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Are There Any Sharp Edges On Any Fixtures?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={SharpEdgesOnFixtures_Yes}
                name="SharpEdgesOnFixtures"
                value="Yes"
                required
              />
              <label htmlFor={SharpEdgesOnFixtures_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={SharpEdgesOnFixtures_No}
                name="SharpEdgesOnFixtures"
                value="No"
                required
              />
              <label htmlFor={SharpEdgesOnFixtures_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={SharpEdgesOnFixtures_NA}
                name="SharpEdgesOnFixtures"
                value="N/A"
                required
              />
              <label htmlFor={SharpEdgesOnFixtures_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="SharpEdgesOnFixturesComments"
              required
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="SharpEdgesOnFixturesActionTaken"
              required
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Is The Fittings Area Neat And Tidy?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FittingsAreaNeat_Yes}
                name="FittingsAreaNeat"
                value="Yes"
                required
              />
              <label htmlFor={FittingsAreaNeat_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FittingsAreaNeat_No}
                name="FittingsAreaNeat"
                value="No"
                required
              />
              <label htmlFor={FittingsAreaNeat_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FittingsAreaNeat_NA}
                name="FittingsAreaNeat"
                value="N/A"
                required
              />
              <label htmlFor={FittingsAreaNeat_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="FittingsAreaNeatComments"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="FittingsAreaNeatActionTaken"
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Cleaning Room Is Locked And Tidy?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={CleaningRoomLocked_Yes}
                name="CleaningRoomLocked"
                value="Yes"
                required
              />
              <label htmlFor={CleaningRoomLocked_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={CleaningRoomLocked_No}
                name="CleaningRoomLocked"
                value="No"
                required
              />
              <label htmlFor={CleaningRoomLocked_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={CleaningRoomLocked_NA}
                name="CleaningRoomLocked"
                value="N/A"
                required
              />
              <label htmlFor={CleaningRoomLocked_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="CleaningRoomLockedComments"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="CleaningRoomLockedActionTaken"
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>First Aid Kit Is Located At FOS/Tearoom?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitLocation_Yes}
                name="FirstAidKitLocation"
                value="Yes"
                required
              />
              <label htmlFor={FirstAidKitLocation_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitLocation_No}
                name="FirstAidKitLocation"
                value="No"
                required
              />
              <label htmlFor={FirstAidKitLocation_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitLocation_NA}
                name="FirstAidKitLocation"
                value="N/A"
                required
              />
              <label htmlFor={FirstAidKitLocation_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="FirstAidKitLocationComments"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="FirstAidKitLocationActionTaken"
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>First Aid Kit Content Has Been Checked And Is In Date?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitContent_Yes}
                name="FirstAidKitContent"
                value="Yes"
                required
              />
              <label htmlFor={FirstAidKitContent_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitContent_No}
                name="FirstAidKitContent"
                value="No"
                required
              />
              <label htmlFor={FirstAidKitContent_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={FirstAidKitContent_NA}
                name="FirstAidKitContent"
                value="N/A"
                required
              />
              <label htmlFor={FirstAidKitContent_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="FirstAidKitContentComments"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="FirstAidKitContentActionTaken"
            ></textarea>
          </div>
          <hr />
          <p>
            <strong>Hi Vis Vest Is Available For Trolley Collection?</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={HiVisVestAvailable_Yes}
                name="HiVisVestAvailable"
                value="Yes"
                required
              />
              <label htmlFor={HiVisVestAvailable_Yes}>Yes</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={HiVisVestAvailable_No}
                name="HiVisVestAvailable"
                value="No"
                required
              />
              <label htmlFor={HiVisVestAvailable_No}>No</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="radio"
                id={HiVisVestAvailable_NA}
                name="HiVisVestAvailable"
                value="N/A"
                required
              />
              <label htmlFor={HiVisVestAvailable_NA}>N/A</label>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Comments:</strong>
            </p>
            <textarea
              rows={5}
              name="HiVisVestAvailableComments"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Action Taken:</strong>
            </p>
            <textarea
              rows={5}
              name="HiVisVestAvailableActionTaken"
            ></textarea>
          </div>
          <hr />
          <div className="stocktake-create-input-row">
            <p>
              <strong>Is the key register up to date?</strong>
            </p>
            <div className="popup-radio-container">
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="radio"
                  id={KeyRegisterUpToDate_Yes}
                  name="KeyRegisterUpToDate"
                  value="Yes"
                  required
                />
                <label htmlFor={KeyRegisterUpToDate_Yes}>Yes</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="radio"
                  id={KeyRegisterUpToDate_No}
                  name="KeyRegisterUpToDate"
                  value="No"
                  required
                />
                <label htmlFor={KeyRegisterUpToDate_No}>No</label>
              </div>
            </div>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Has the monitoring call-out list been updated?</strong>
            </p>
            <div className="popup-radio-container">
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="radio"
                  id={MonitoringCalloutListUpdated_Yes}
                  name="MonitoringCalloutListUpdated"
                  value="Yes"
                  required
                />
                <label htmlFor={MonitoringCalloutListUpdated_Yes}>Yes</label>
              </div>
              <div className="printstoreaddress-settings-radio-container">
                <input
                  type="radio"
                  id={MonitoringCalloutListUpdated_No}
                  name="MonitoringCalloutListUpdated"
                  value="No"
                  required
                />
                <label htmlFor={MonitoringCalloutListUpdated_No}>No</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="footer-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={purple}
          onClick={handleFormSubmit}
        />
      </div>
    </div>
  );
};
