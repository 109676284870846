import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, orange, spURL } from "../../App";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PickHeader = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [PickFilter, setPickFilter] = useState("APPROVED");
  const [OrderTable, setOrderTable] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const refOrderID = useRef();

  const pageLoad = async (PickFilter) => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          PickFilter: PickFilter,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPage("menu");
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        const _userstatus = spResponse.Table[0].UserStatus;

        if (_userstatus === "ASSIGNED") {
          props.setPage("pick-lines");
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          setOrderTable(
            spResponse.Table1.map((item) => {
              let _background = "";

              switch (item.Status) {
                case "APPROVED":
                  _background = "white";
                  break;
                case "ASSIGNED":
                  _background = "yellow";
                  break;
                case "COMPLETED":
                  _background = "lightgreen";
                  break;
                case "ISSUES":
                  _background = "orange";
                  break;
                default:
                  _background = "hotpink";
                  break;
              }

              return {
                CartID: item.CartID,
                Status: item.Status,
                Categories: item.Categories,
                AssignedStaff: item.AssignedStaff,
                Background: _background,
              };
            })
          );
        } else {
          setOrderTable([]);
        }
      } else {
        setOrderTable([]);
      }
    }
  };

  useEffect(() => {
    pageLoad(PickFilter);
  }, []);

  const handleClick_Search = () => {
    setWarehouseOrderingResponse("");

    const _orderid = refOrderID.current.value;

    // if orderID exists in table
    if (OrderTable.map((item) => item.CartID.toString()).includes(_orderid)) {
      // find rowIndex of the orderID existing in table
      const index = OrderTable.findIndex((item) => item.CartID.toString() === _orderid);

      // click on the orderID cell on the row found
      document.getElementById("weborders-ordertable-rows").rows[index].cells[0].click();
    } else {
      setWarehouseOrderingResponse(`ERROR!: Order ${_orderid} not found.`);
    }
  };

  const handleClick_Back = () => {
    setWarehouseOrderingResponse("");
    props.setPage("menu");
  };

  const handleClick_Row = (_order) => {
    setWarehouseOrderingResponse("");

    const isOrderAlreadySelected = selectedOrder
      .map((order) => order.CartID)
      .includes(_order.CartID);

    switch (PickFilter) {
      case "APPROVED":
      case "ASSIGNED":
      case "ISSUES":
      case "ALLOCATED":
        if (isOrderAlreadySelected) {
          setSelectedOrder(selectedOrder.filter((order) => order.CartID !== _order.CartID));
        } else {
          setSelectedOrder([_order]);
        }
        break;
    }

    refOrderID.current.value = "";
  };

  const handleClick_Pick = async () => {
    setWarehouseOrderingResponse("");

    if (selectedOrder.length === 0) {
      setWarehouseOrderingResponse("Please select an order to continue.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Assign`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              CartID: item.CartID,
              UserID: userid,
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setWarehouseOrderingResponse(resObj.exception);
      } else {
        props.setPage("pick-lines");
      }
    }
  };

  const handleClick_PickFilter = (_filter) => {
    setWarehouseOrderingResponse("");
    setOrderTable([]);
    setSelectedOrder([]);
    setPickFilter(_filter);
    pageLoad(_filter);
  };

  const handleClick_Unassign = async () => {
    setWarehouseOrderingResponse("");

    if (selectedOrder.length === 0) {
      setWarehouseOrderingResponse("Please select an order to continue.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Commit`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              CartID: item.CartID,
              UserID: userid,
              Status: "Unassign",
            }))
          ),
        }),
      });

      if (resObj.exception !== "") {
        setWarehouseOrderingResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setWarehouseOrderingResponse("OK!: Orders unassigned.");
        await pageLoad(PickFilter);
      }
    }
  };

  return (
    <div className="weborders-page-container">
      <div className="weborders-top-row">
        <div className="weborders-option-row">
          <ContentButton
            button=""
            colour="white"
            id="weborders-pickfilter-new"
            onClick={() => {
              handleClick_PickFilter("APPROVED");
            }}
          />
          <ContentButton
            button=""
            colour="yellow"
            id="weborders-pickfilter-assigned"
            onClick={() => handleClick_PickFilter("ASSIGNED")}
          />
          <ContentButton
            button=""
            colour="lightgreen"
            id="weborders-pickfilter-completed"
            onClick={() => handleClick_PickFilter("COMPLETED")}
          />
          <ContentButton
            button=""
            colour="orange"
            id="weborders-pickfilter-issues"
            onClick={() => {
              handleClick_PickFilter("ISSUES");
            }}
          />
          <ContentButton
            button=""
            colour="black"
            id="weborders-pickfilter-allocated"
            onClick={() => handleClick_PickFilter("ALLOCATED")}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>OrderID</strong>
          </p>
          <input
            type="text"
            id="weborders-orderid"
            ref={refOrderID}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
        </div>
        <button
          className="signin-login-button hidden"
          id="weborders-enter"
          onClick={handleClick_Search}
        >
          Search
        </button>
        <p>
          <strong>{PickFilter} Orders Only</strong>
        </p>
        <p className="error-message">{WarehouseOrderingResponse}</p>
      </div>
      <div className="handheld-body-container">
        <div>
          {OrderTable.length === 0 ? (
            <p className="error-message">Currently no {PickFilter} orders.</p>
          ) : (
            <>
              <table
                className="itemdetails-table"
                id="weborders-ordertable"
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    {PickFilter === "ALLOCATED" ? <th>Assigned Staff</th> : <th>Categories</th>}
                  </tr>
                </thead>
                <tbody id="weborders-ordertable-rows">
                  {OrderTable.map((item, index) => (
                    <tr
                      key={index}
                      className={`btnHover no-highlight background-${
                        selectedOrder.map((order) => order.CartID).includes(item.CartID)
                          ? "lightblue"
                          : item.Background
                      }`}
                      onClick={() => handleClick_Row(item)}
                    >
                      <td>{item.CartID}</td>
                      {PickFilter === "ALLOCATED" ? (
                        <td>{item.AssignedStaff}</td>
                      ) : (
                        <td dangerouslySetInnerHTML={{ __html: item.Categories }}></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
            </>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        {OrderTable === "" ? (
          ""
        ) : PickFilter === "APPROVED" || PickFilter === "ISSUES" ? (
          <FooterButton
            button="Pick"
            colour={orange}
            onClick={handleClick_Pick}
          />
        ) : PickFilter === "COMPLETED" ? (
          // <FooterButton
          //   button="Pack"
          //   colour={green}
          //   onClick={handleClick_Pack}
          // />
          <></>
        ) : PickFilter === "ALLOCATED" ? (
          <FooterButton
            button="UnAll"
            colour={orange}
            onClick={handleClick_Unassign}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
