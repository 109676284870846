import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupChangeQuantity } from "./PopupChangeQuantity";

import { red, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { PopupAddItem } from "./PopupAddItem";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";

export const Manage = (props) => {
  const [ddlFunction, setDDLFunction] = useState([]);
  const [ScannedItemTable, setScannedItemTable] = useState([]);
  const [ShowItemIDRow, setShowItemIDRow] = useState(false);
  const [ShowAdd, setShowAdd] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupAddItem, setShowPopupAddItem] = useState(false);
  const [popupItemNo, setPopupItemNo] = useState("");
  const [popupQty, setPopupQty] = useState("");
  const [popupStockTake, setPopupStockTake] = useState("");
  const [popupBayNo, setPopupBayNo] = useState("");
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const refBayNo = useRef();
  const refFunction = useRef();
  const refItemID = useRef();

  useEffect(() => {
    async function getFunctions() {
      const resObj = await runFetch(`${spURL_NoInputJSON}Stocktake_ddlFunction_Get`, {
        method: "POST",
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLFunction(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Function}
              >
                {item.Function}
              </option>
            ))
          );
        }
      }
      if (props.From === "CHECK") {
        refBayNo.current.value = props.StockTakeBay.BayNo;
        refFunction.current.value = "CHANGE QUANTITY";
        await handleChange();
      }
    }
    getFunctions();
  }, []);

  const handleChange = async () => {
    setStockTakeResponse("");
    setShowItemIDRow(false);

    switch (refFunction.current.value) {
      case "CHANGE QUANTITY":
        await getItemList();
        setShowAdd(true);
        break;
      case "HISTORY SEARCH":
        setShowItemIDRow(true);
        setScannedItemTable([]);
        setShowAdd(false);
        break;
      default:
        setScannedItemTable([]);
        setShowAdd(false);
        break;
    }

    async function getItemList() {
      const resObj = await runFetch(`${spURL}Stocktake_Bay_ScannedItemList`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: props.FromPage.StoreNo,
            BayNo: refBayNo.current.value,
            StockTake: props.CurrentStockTake,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table.length > 0) {
          setScannedItemTable(
            spResponse.Table.map((item, index) => (
              <tr
                key={index}
                name={item.Item}
                qty={item.Counted}
                onClick={() => {
                  setShowPopup(true);

                  setPopupItemNo(item.Item);
                  setPopupQty(item.Counted);
                  setPopupStockTake(props.CurrentStockTake);
                  setPopupBayNo(refBayNo.current.value);
                }}
                className="btnHover"
              >
                <td
                  name={item.Item}
                  qty={item.Counted}
                >
                  {item.Item}
                </td>
                <td
                  name={item.Item}
                  qty={item.Counted}
                >
                  {item.Description}
                </td>
                <td
                  name={item.Item}
                  qty={item.Counted}
                >
                  {item.Counted}
                </td>
              </tr>
            ))
          );
        } else {
          setScannedItemTable([]);
          setStockTakeResponse("ERROR!: No item scanned in bay.");
        }
      }
    }
  };

  const handleClick = async () => {
    switch (refFunction.current.value) {
      case "HISTORY SEARCH":
        await showHistory();
        break;
      case "CHANGE QUANTITY":
        break;
      default:
        await processFunction();
        break;
    }

    async function processFunction() {
      const resObj = await runFetch(`${spURL}Stocktake_Function_Process`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: props.FromPage.StoreNo,
            BayNo: refBayNo.current.value,
            StockTake: props.CurrentStockTake,
            Function: refFunction.current.value,
            From: "",
            Quantity: "",
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setStockTakeResponse(spResponse.Table[0].OutputString);
        }
      }
    }

    async function showHistory() {
      const resObj = await runFetch(`${spURL}Stocktake_Search_History`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: props.FromPage.StoreNo,
            BayNo: refBayNo.current.value,
            Barcode: refItemID.current.value,
            StockTake: props.CurrentStockTake,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
        setScannedItemTable([]);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table.length > 0) {
          setScannedItemTable(
            spResponse.Table.map((item, index) => (
              <tr
                key={index}
                style={{ fontSize: 12 }}
              >
                <td>{item.Item}</td>
                <td>{item.Description}</td>
                <td>{item.Counted}</td>
                <td>{ConvertDateFormat(item.Time)}</td>
              </tr>
            ))
          );
        } else {
          setScannedItemTable([]);
          setStockTakeResponse("ERROR!: No item scanned in bay.");
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    setStockTakeResponse("");
    if (event.key === "Enter") {
      event.preventDefault();
      handleClick();

      refItemID.current.select();
    }
  };

  const handleClick_Back = () => {
    props.setFrom("");
    props.setStockTakeBay("");
    props.setPage("menu");
  };

  const handleClick_Add = () => {
    setShowPopupAddItem(true);

    setPopupStockTake(props.CurrentStockTake);
    setPopupBayNo(refBayNo.current.value);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Bay:</strong>
            </p>
            <input
              type="text"
              id="stocktake-manage-bayno"
              onChange={handleChange}
              ref={refBayNo}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Function:</strong>
            </p>
            <select
              name="stocktake"
              id="stocktake-manage-function"
              onChange={handleChange}
              ref={refFunction}
            >
              <option value=""></option>
              {ddlFunction}
            </select>
          </div>
          {ShowItemIDRow && (
            <div className="stocktake-create-input-row">
              <p>
                <strong>ItemID:</strong>
              </p>
              <input
                type="text"
                id="stocktake-manage-itemid"
                onKeyDown={handleKeyDown}
                ref={refItemID}
              />
            </div>
          )}
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            Ok
          </button>
          {ScannedItemTable && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <td>
                    <strong>Item</strong>
                  </td>
                  <td>
                    <strong>Description</strong>
                  </td>
                  <td>
                    <strong>Counted</strong>
                  </td>
                  {ShowItemIDRow && (
                    <td>
                      <strong>Time</strong>
                    </td>
                  )}
                </tr>
              </thead>
              <tbody>{ScannedItemTable}</tbody>
            </table>
          )}
        </div>

        <PopupChangeQuantity
          show={showPopup}
          setShow={setShowPopup}
          setPage={props.setPage}
          ItemNo={popupItemNo}
          Qty={popupQty}
          StockTake={popupStockTake}
          BayNo={popupBayNo}
          handleChange={handleChange}
          setStockTakeResponse={setStockTakeResponse}
          FromPage={props.FromPage}
        />

        <PopupAddItem
          show={showPopupAddItem}
          setShow={setShowPopupAddItem}
          StockTake={props.CurrentStockTake}
          BayNo={popupBayNo}
          handleChange={handleChange}
          setStockTakeResponse={setStockTakeResponse}
          FromPage={props.FromPage}
        />

        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={red}
          onClick={props.handleClick_Home}
        />
        {ShowAdd && (
          <FooterButton
            button="Add"
            colour={red}
            onClick={handleClick_Add}
          />
        )}
      </div>
    </div>
  );
};
