import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAccessData, orange } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import store from "../../store";

export const Admin = (props) => {
  const [userid, ,] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState("");

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          {Access.WarehouseOrderingAdmin && (
            <>
              <MenuButton
                onClick={() => props.setPage("approve")}
                button="AO"
                colour={orange}
                title="Approve Order"
              />
              <MenuButton
                onClick={() => props.setPage("item-maintenance")}
                button="IM"
                colour={orange}
                title="Item Maintenance"
              />
            </>
          )}
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
