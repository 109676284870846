import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { isInputWholeInteger } from "../../functions/IsInputWholeInteger";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";
import { getDistinctValuesFromArray } from "../../functions/GetDistinctValuesFromArray";

export const PopupCreateItem = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [Category, setCategory] = useState("");
  const [Description, setDescription] = useState("");
  const [WHInStock, setWHInStock] = useState("1");

  const refCategory = useRef();
  const refDescription = useRef();
  const refWHInStock_Yes = useRef();
  const refWHInStock_No = useRef();

  const handleClose = () => {
    props.setShow(false);

    setCategory("");
    setDescription("");
    setWHInStock("1");

    refCategory.current.value = "";
    refDescription.current.value = "";
    refWHInStock_Yes.current.checked = true;

    setPopupResponse("");
  };

  const handleClick_CreateItem = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_ItemMaintenance_CreateItem`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Category: Category,
          Description: Description,
          WarehouseInStock: WHInStock,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
        props.getItems();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Category:</strong>
              </p>
              <select
                ref={refCategory}
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setCategory);
                }}
              >
                <option value=""></option>
                {getDistinctValuesFromArray(props.ItemList.map((item) => item.Category)).map(
                  (cat, index) => (
                    <option
                      value={cat}
                      key={index}
                    >
                      {cat}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <input
                ref={refDescription}
                type="text"
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setDescription);
                }}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Warehouse In Stock:</strong>
              </p>
              <div className="popup-radio-container">
                <input
                  ref={refWHInStock_Yes}
                  type="radio"
                  id="popup-yes"
                  name="popup-whinstock"
                  value="1"
                  onChange={(e) => handleChange_Input(e, setWHInStock)}
                  className="btnHover no-highlight"
                  defaultChecked
                />
                <label
                  className="btnHover no-highlight"
                  htmlFor="popup-yes"
                >
                  Yes
                </label>
                <input
                  ref={refWHInStock_No}
                  type="radio"
                  id="popup-no"
                  name="popup-whinstock"
                  value="0"
                  onChange={(e) => handleChange_Input(e, setWHInStock)}
                  className="btnHover no-highlight"
                />
                <label
                  className="btnHover no-highlight"
                  htmlFor="popup-no"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_CreateItem}
            >
              <strong>Create</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
