import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PopupPhoto = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [ImageURL, setImageURL] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    setImageURL("");
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");
    const resObj = await runFetch(`${spURL}Stocktake_Bay_ImageURL_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.FromPage.StoreNo,
          StockTake: props.CurrentStockTake,
          BayNo: props.SelectedBay.BayNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setImageURL(spResponse.Table[0].BayImageURL);
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <img
              style={{ width: "100%" }}
              src={ImageURL}
            />
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
