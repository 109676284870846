import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupReceipt = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [ReceiptString, setReceiptString] = useState("");

  const refEmail = useRef();

  const handleClose = () => {
    props.setShow(false);
    setPopupResponse("");
    setReceiptString("");
    refEmail.current.value = "";
  };

  const getReceiptString = async (_receiptid, _receipttype) => {
    const resObj = await runFetch(`${spURL}Handheld_TheftReceipt_GetReceiptString`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TheftReceiptID: _receiptid,
          ReceiptType: _receipttype,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        return spResponse.Table[0].ReceiptString;
      } else {
        return "";
      }
    }
  };

  const handleShow = async () => {
    setReceiptString(await getReceiptString(props.SelectedTheftReceipt.TheftReceiptID, "PRINT"));
    refEmail.current.focus();
  };

  const handleClick_Email = async () => {
    setPopupResponse("");

    let finalEmailString = "";

    finalEmailString += `Theft Receipt 1\n`;
    finalEmailString += await getReceiptString(props.SelectedTheftReceipt.TheftReceiptID, "PRINT");

    if (refEmail.current.value !== "") {
      const resObj = await runFetch(`${spURL}SendReceiptEmail`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Email: refEmail.current.value,
            PrintString: finalEmailString,
            ReceiptNo: props.SelectedTheftReceipt.TheftReceiptID,
          }),
        }),
      });

      if (resObj.response === null) {
        setPopupResponse(resObj.exception);
      } else {
        props.setTheftReceiptResponse(resObj.response);
        handleClose();
      }
    } else {
      setPopupResponse("ERROR!: Invalid email address.");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Email:</strong>
            </p>
            <input
              type="text"
              ref={refEmail}
            />
          </div>
          <div className="receipt-preview-container">
            <pre>{ReceiptString}</pre>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Email}
            >
              <strong>Email</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
