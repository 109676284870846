import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { isInputWholeInteger } from "../../functions/IsInputWholeInteger";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";

export const PopupAddToCart = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [Qty, setQty] = useState("");

  const refQty = useRef();

  const handleClose = () => {
    props.setShow(false);

    setQty("");
    refQty.current.value = "";

    setPopupResponse("");
  };

  const handleShow = () => {
    setPopupResponse("");

    if (props.Type === "Edit") {
      setQty(props.SelectedItem.Qty);
      refQty.current.value = props.SelectedItem.Qty;
    }

    refQty.current.select();
  };

  const handleClick_AddToCart = async () => {
    setPopupResponse("");

    if (!isInputWholeInteger(Qty)) {
      setPopupResponse("ERROR!: Qty needs to be whole number.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_Cart_Item_${props.Type}`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            CartID: props.CartID,
            SKU: props.SelectedItem.SKU,
            Qty: Qty,
          }),
        }),
      });

      if (resObj.response === null) {
        setPopupResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          props.setWarehouseOrderingResponse(spResponse.Table[0].OutputString);
          props.getCart();
          handleClose();
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SKU:</strong>
              </p>
              <p>{props.SelectedItem.SKU}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Item:</strong>
              </p>
              <p>{props.SelectedItem.Item}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Qty:</strong>
              </p>
              <input
                type="text"
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setQty);
                }}
                ref={refQty}
              />
            </div>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_AddToCart}
            >
              <strong>{props.Type}</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
