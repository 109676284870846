import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { Logout } from "../functions/Logout";
import store from "../store";

library.add(faUser);

export const Header = (props) => {
  const [show, setShow] = useState(false);

  const handleClick_User = () => {
    setShow(true);
  };

  const headerTitle = props.page;

  return (
    <div className="header-title-background">
      <div className="header-title-container text-center">
        <h1 className="header-title">
          {headerTitle}
          {props.page === "Sign In" ||
          props.page === "Device Locked" ||
          props.page === "Device Verification" ||
          props.page.includes("Stock Take") ? (
            ""
          ) : (
            <FontAwesomeIcon
              className="usericon-top-right btnHover"
              icon="far fa-user"
              onClick={handleClick_User}
            />
          )}
        </h1>
      </div>

      <PopupUserInfo
        show={show}
        setShow={setShow}
        doLogout={props.doLogout}
      />
    </div>
  );
};

export const PopupUserInfo = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [Items, setItems] = useState([]);

  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [username, setUserName] = store.useState("username");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");

  const handleClose = () => props.setShow(false);

  const handleLogout = () => {
    props.doLogout();
    props.setShow(false);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="userinfo-input-row">
              <p>
                <strong>User:</strong> {username}
              </p>
            </div>
            <div className="userinfo-input-row">
              <p>
                <strong>StoreNo:</strong> {storeno}
              </p>
            </div>
            <div className="userinfo-input-row">
              <p>
                <strong>StoreName:</strong> {storename}
              </p>
            </div>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleLogout}
            >
              <strong>Logout</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
