import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, blue, getAccessData } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const Menu = (props) => {
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState(
    props.StoreComplianceToolResponse
  );

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(props.From.UserID));
    }

    getAccess();
  }, [props.From]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => {
              props.setStoreComplianceToolResponse("");
              props.setPage("tasklist");
            }}
            button="T"
            colour={blue}
            title="Tasks"
          />
          {Access.StoreComplianceToolAdmin && (
            <MenuButton
              onClick={() => {
                props.setStoreComplianceToolResponse("");
                props.setPage("admin-tasklist");
              }}
              button="A"
              colour={blue}
              title="Admin"
            />
          )}
        </div>
        <p className="error-message">{StoreComplianceToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={props.handleClick_Home}
        />
        <FooterButton
          button="Home"
          colour={blue}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
