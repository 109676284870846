import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DailySalesPerformance } from "./DailySalesPerformance";
import { Menu } from "./Menu";
import { AfterHoursSales } from "./AfterHoursSales";

export const ReportsBody = () => {
  const [page, setPage] = useState("menu");

  const menuPage = <Menu page={page} setPage={setPage} />;

  const dailySalesPerformancePage = <DailySalesPerformance page={page} setPage={setPage} />;

  const afterHoursSalesPage = <AfterHoursSales page={page} setPage={setPage} />;

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "dailysalesperformance":
        return dailySalesPerformancePage;
      case "afterhourssales":
        return afterHoursSalesPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
