import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PopupHistory } from "./PopupHistory";
import { PopupQuantityScan } from "./PopupQuantityScan";

import ErrorSound from "./../../assets/sound/WindowsError.wav";

import useSound from "use-sound";
import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { ItemBarcode } from "../../components/ItemBarcode";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";
import { PopupCameraScreen } from "./PopupCameraScreen";
import { BlobServiceClient } from "@azure/storage-blob";
import is from "is_js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Scan = (props) => {
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstocktakeimages"); // create a containerClient

  const [isStockTakeSelected, setIsStockTakeSelected] = useState(false);

  const emptyCurrentStockTake = {
    StockTake: props.CurrentStockTake,
    BayNo: "",
    BayImageURL: "",
  };
  const [currentStockTake, setCurrentStockTake] = useState(emptyCurrentStockTake);

  const [currentMode, setCurrentMode] = useState("QUICK");

  const [popupBarcode, setPopupBarcode] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const emptyUploadedImageFile = {
    name: "",
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);
  const [IsImageModified, setIsImageModified] = useState(false);

  const [play] = useSound(ErrorSound);

  const refBayNo = useRef();

  useEffect(() => {
    setCurrentStockTake((values) => ({
      ...values,
      StockTake: props.CurrentStockTake,
    }));

    setStockTakeResponse("");
  }, []);

  const handleClick_ChangeBay = async () => {
    if (is.mobile() || is.tablet()) {
      if (UploadedImageFile.blob === "") {
        setStockTakeResponse("ERROR!: Image must be uploaded before changing bay.");
      } else {
        handleClick_Select();
      }
    } else {
      handleClick_Select();
    }
  };

  const handleClick_Select = async () => {
    setStockTakeResponse("");
    setUploadedImageFile(emptyUploadedImageFile);

    if (isStockTakeSelected) {
      await lockBay();
      setIsStockTakeSelected(false);
      setCurrentStockTake(emptyCurrentStockTake);
    } else {
      let bayNo = refBayNo.current.value;

      if (bayNo !== "") {
        let resp = await canUserAssignedToBay(bayNo);
        if (resp === "OK!") {
          setCurrentStockTake((values) => ({
            ...values,
            BayNo: bayNo,
          }));
          setIsStockTakeSelected(true);
          getStockTakeImage(bayNo);
        } else {
          setStockTakeResponse(resp);
        }
      } else {
        setStockTakeResponse("ERROR!: No StockTake or Bay selected.");
      }
    }
  };

  const canUserAssignedToBay = async (_bayno) => {
    const resObj = await runFetch(`${spURL}Stocktake_Bay_CheckAssignedBay`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.FromPage.StoreNo,
          StockTake: currentStockTake.StockTake,
          BayNo: _bayno,
          UserID: props.FromPage.UserID,
        }),
      }),
    });

    if (resObj.response === null) {
      return resObj.exception;
    } else {
      return "OK!";
    }
  };

  const getStockTakeImage = async (bayNo) => {
    const resObj = await runFetch(`${spURL}Stocktake_Bay_ImageURL_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.FromPage.StoreNo,
          StockTake: currentStockTake.StockTake,
          BayNo: bayNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        const _imageurl = spResponse.Table[0].BayImageURL;

        setCurrentStockTake((values) => ({
          ...values,
          BayImageURL: _imageurl,
        }));
      }
    }
  };

  const lockBay = async () => {
    const resObj = await runFetch(`${spURL}Stocktake_Function_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: props.FromPage.StoreNo,
          BayNo: currentStockTake.BayNo,
          StockTake: currentStockTake.StockTake,
          Function: "LOCK BAY",
          From: "QUICK",
          Quantity: "",
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table.length > 0) {
          const _response = spResponse.Table[0].OutputString;

          setStockTakeResponse(_response);

          if (_response === "OK!: Stocktake Bay Locked.") {
            setCurrentStockTake(emptyCurrentStockTake);

            if (currentMode === "QUANTITY") {
              handleClick_Toggle();
            }
          }
        }
      }
    }
  };

  const handleClick_Back = () => {
    document.getElementById("root").classList.remove("background-lightblue");

    if (currentMode === "QUANTITY") {
      handleClick_Toggle();
    }

    props.setPage("menu");
  };

  const handleClick_Barcode = async () => {
    async function getAddItem() {
      const _barcode = document.getElementById("itembarcode-barcode").value;

      const resObj = await runFetch(`${spURL}StockTake_Item_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: props.FromPage.StoreNo,
            Barcode: _barcode,
            StockTake: currentStockTake.StockTake,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
        play();
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          await addItem(spResponse.Table[0].ItemNo);
        } else {
          setStockTakeResponse("ERROR!: Item not found.");
          play();
        }
      }
      clearBarcode();
    }

    async function addItem(itemno) {
      const resObj = await runFetch(`${spURL}StockTake_Add_Count`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: props.FromPage.StoreNo,
            ItemNo: itemno,
            StockTake: currentStockTake.StockTake,
            Quantity: 1,
            UserID: props.FromPage.UserID,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length > 0) {
            setStockTakeResponse(spResponse.Table[0].OutputString);
          }

          if (spResponse.Table[0].OutputString.includes("OK!:") === false) {
            play();
          }
        }
      }
    }

    async function checkBlocked() {
      const _barcode = document.getElementById("itembarcode-barcode").value;

      const resObj = await runFetch(`${spURL}Stocktake_QuantityCount_Blocklist_Check`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: props.FromPage.StoreNo,
            StockTake: currentStockTake.StockTake,
            Barcode: _barcode,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse("ERROR!: This sub category can not be quantity scanned.");
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          if (spResponse.Table[0].OutputString === "OK!") {
            setPopupBarcode(_barcode);

            setShowPopup(true);
          }
        } else {
          setStockTakeResponse("ERROR!: This sub category can not be quantity scanned.");
        }
      }
    }

    if (currentMode === "QUICK") {
      await getAddItem();
    } else if (currentMode === "QUANTITY") {
      // popup for quantity scan
      await checkBlocked();
    }
  };

  const handleClick_Toggle = () => {
    if (currentMode === "QUICK") {
      setCurrentMode("QUANTITY");

      document.getElementById("root").className = "background-lightblue";
    } else {
      setCurrentMode("QUICK");
      document.getElementById("root").className = "";
    }

    document.getElementById("itembarcode-barcode").focus();
  };

  const handleClick_History = () => {
    setShowHistory(true);
  };

  const clearBarcode = () => {
    document.getElementById("itembarcode-barcode").value = "";
    document.getElementById("itembarcode-barcode").focus();
  };

  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          let imgObj = {
            name: `${props.FromPage.StoreNo}_${currentStockTake.StockTake.replaceAll(" ", "")}_${
              currentStockTake.BayNo
            }.jpg`,
            blob: imgBlob,
          };
          setUploadedImageFile(imgObj);
          setIsImageModified(true);

          return imgObj;
        })
        .then(async (imgObj) => {
          const blobClient = containerClient.getBlockBlobClient(imgObj.name);
          await trackPromise(blobClient.uploadData(imgObj.blob));
          await saveURL(imgObj.name);
        })
        .catch((err) => {
          setStockTakeResponse(err);
        })
    );
  };

  const saveURL = async (_imagename) => {
    const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstocktakeimages/${encodeURIComponent(
      _imagename
    )}`;

    const resObj = runFetch(`${spURL}Stocktake_Bay_ImageURL_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.FromPage.StoreNo,
          StockTake: currentStockTake.StockTake,
          BayNo: currentStockTake.BayNo,
          ImageURL: blobURL,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      // let spResponse = JSON.parse(resObj.response);

      await handleClick_Select();
      // if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
      //   // await lockBay();
      // }
    }
  };

  return (
    <div
      className="page-container"
      id="modeBackground"
    >
      <div className="handheld-body-container">
        <div>
          {isStockTakeSelected ? (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Stock Take:</strong>
                </p>
                <p>{currentStockTake.StockTake}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Bay:</strong>
                </p>
                <p>{currentStockTake.BayNo}</p>
              </div>
            </>
          ) : (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Stock Take:</strong>
                </p>
                <p>{currentStockTake.StockTake}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Bay:</strong>
                </p>
                <input
                  type="text"
                  id="stocktake-scan-bayno"
                  ref={refBayNo}
                />
              </div>
            </>
          )}

          <p className={StockTakeResponse.includes("OK!") ? "success-message" : "error-message"}>
            {StockTakeResponse}
          </p>

          {isStockTakeSelected ? (
            <></>
          ) : (
            <button
              className="signin-login-button btnHover"
              onClick={handleClick_Select}
            >
              Select
            </button>
          )}

          {isStockTakeSelected && (
            <div>
              <br />
              <ItemBarcode barcodeClick={handleClick_Barcode} />
              <br />
              <div className="input-row">
                <p>
                  <strong>Current Mode:</strong>
                </p>
                <p>{currentMode}</p>
              </div>
              <br />
              <div className="blockedorders-option-row">
                <div className="storeaudittool-fileupload-container">
                  {IsImageModified ? (
                    UploadedImageFile.blob === "" ? (
                      <MenuButton
                        className="no-highlight"
                        button="UP"
                        colour={red}
                        title="Upload Photo"
                        onClick={() => setShowCamera(true)}
                      />
                    ) : (
                      <>
                        <img
                          className="storeaudittool-image"
                          src={URL.createObjectURL(UploadedImageFile.blob)}
                          onClick={() => setShowCamera(true)}
                        />
                        <FontAwesomeIcon
                          className="storeaudittool-deleteimage-icon btnHover"
                          icon="fas fa-times"
                          onClick={() => {
                            setIsImageModified(true);
                            setUploadedImageFile(emptyUploadedImageFile);
                          }}
                        />
                      </>
                    )
                  ) : currentStockTake.BayImageURL === "" ? (
                    <MenuButton
                      className="no-highlight"
                      button="UP"
                      colour={red}
                      title="Upload Photo"
                      onClick={() => setShowCamera(true)}
                    />
                  ) : (
                    <>
                      <img
                        className="storeaudittool-image"
                        src={currentStockTake.BayImageURL}
                        onClick={() => setShowCamera(true)}
                      />
                      <FontAwesomeIcon
                        className="storeaudittool-deleteimage-icon btnHover"
                        icon="fas fa-times"
                        onClick={() => {
                          setIsImageModified(true);
                          setUploadedImageFile(emptyUploadedImageFile);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="History"
                  colour={red}
                  button="H"
                  onClick={handleClick_History}
                />
                <MenuButton
                  title="Change Bay"
                  colour={red}
                  button="CB"
                  onClick={handleClick_ChangeBay}
                />
                <MenuButton
                  title="Toggle Mode"
                  colour={red}
                  button="TM"
                  onClick={handleClick_Toggle}
                />
              </div>
            </div>
          )}

          <PopupQuantityScan
            show={showPopup}
            setShow={setShowPopup}
            setPage={props.setPage}
            Barcode={popupBarcode}
            StockTake={currentStockTake.StockTake}
            BayNo={currentStockTake.BayNo}
            handleClick_Toggle={handleClick_Toggle}
            clearBarcode={clearBarcode}
            setStockTakeResponse={setStockTakeResponse}
            play={play}
            FromPage={props.FromPage}
          />

          <PopupHistory
            show={showHistory}
            setShow={setShowHistory}
            StocktakeID={currentStockTake.StockTake}
            BayNo={currentStockTake.BayNo}
            setStockTakeResponse={setStockTakeResponse}
            FromPage={props.FromPage}
          />

          <PopupCameraScreen
            show={showCamera}
            setShow={setShowCamera}
            processSnappedImage={processSnappedImage}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        {!isStockTakeSelected && (
          <>
            <FooterButton
              button="Back"
              colour={red}
              onClick={handleClick_Back}
            />
            <FooterButton
              button="Home"
              colour={red}
              onClick={() => {
                document.getElementById("root").classList.remove("background-lightblue");
                props.handleClick_Home();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
