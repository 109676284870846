import { useEffect, useRef, useState } from "react";
import store from "../../store";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { spURL, yellow } from "../../App";
import { Link } from "react-router-dom";
import { FooterButton } from "../../components/HomepageButton";
import { PopupItem } from "./PopupItem";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { PopupReceipt } from "./PopupReceipt";

export const Add = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");
  const [storename, ,] = store.useState("storename");

  const [TheftReceiptResponse, setTheftReceiptResponse] = useState("");

  const [ScannedBarcode, setScannedBarcode] = useState("");

  const [ReceiptItemList, setReceiptItemList] = useState([]);

  const [SelectedItemQty, setSelectedItemQty] = useState(0);

  const [showAddItem, setShowAddItem] = useState(false);
  const [showEditItem, setShowEditItem] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);

  const refBarcode = useRef();

  const getReceiptLines = async (signal = null) => {
    const resObj = await runFetch(`${spURL}Handheld_TheftReceipt_GetLines`, {
      method: "POST",
      signal: signal,
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TheftReceiptID: props.SelectedTheftReceipt.TheftReceiptID,
        }),
      }),
    });

    if (resObj.response === null) {
      setTheftReceiptResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setReceiptItemList(
          spResponse.Table.map((item) => ({
            TheftReceiptLineID: item.TheftReceiptLineID,
            ItemNo: item.ItemNo,
            Description: item.Description,
            Qty: item.Qty,
            Price: item.Price,
          }))
        );
      }
    }
  };

  useInitialEffect(getReceiptLines);

  const handleClick_Barcode = () => {
    setTheftReceiptResponse("");

    setScannedBarcode(refBarcode.current.value);
    setShowAddItem(true);
  };

  const handleClick_Row = (_item) => {
    setTheftReceiptResponse("");
    setScannedBarcode(_item.ItemNo);
    setSelectedItemQty(_item.Qty);
    setShowEditItem(true);
  };

  const handleClick_Rcpt = () => {
    setTheftReceiptResponse("");
    setShowReceipt(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Receipt:</strong>
          </p>
          <p>{props.SelectedTheftReceipt.TheftReceiptName}</p>
        </div>
        <p className="error-message">{TheftReceiptResponse}</p>
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Barcode</strong>
            </p>
            <input
              type="text"
              ref={refBarcode}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Barcode)}
            />
          </div>
          <button
            className="signin-login-button"
            onClick={handleClick_Barcode}
          >
            Go
          </button>

          <br />
          <br />

          <h5>
            <strong>Scanned Item List</strong>
          </h5>
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>ItemNo</th>
                <th>Description</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {ReceiptItemList.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    handleClick_Row(item);
                  }}
                  className="btnHover no-highlight"
                >
                  <td>{item.ItemNo}</td>
                  <td>{item.Description}</td>
                  <td>{item.Qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <PopupItem
          show={showAddItem}
          setShow={setShowAddItem}
          ScannedBarcode={ScannedBarcode}
          SelectedTheftReceipt={props.SelectedTheftReceipt}
          cleanBarcode={() => {
            refBarcode.current.value = "";
            setScannedBarcode("");
          }}
          getReceiptLines={getReceiptLines}
          setTheftReceiptResponse={setTheftReceiptResponse}
          Action="ADD"
        />

        <PopupItem
          show={showEditItem}
          setShow={setShowEditItem}
          ScannedBarcode={ScannedBarcode}
          SelectedTheftReceipt={props.SelectedTheftReceipt}
          cleanBarcode={() => {
            refBarcode.current.value = "";
            setScannedBarcode("");
          }}
          getReceiptLines={getReceiptLines}
          setTheftReceiptResponse={setTheftReceiptResponse}
          SelectedItemQty={SelectedItemQty}
          Action="EDIT"
        />

        <PopupReceipt
          show={showReceipt}
          setShow={setShowReceipt}
          SelectedTheftReceipt={props.SelectedTheftReceipt}
          setTheftReceiptResponse={setTheftReceiptResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Rcpt"
          colour={yellow}
          onClick={handleClick_Rcpt}
        />
      </div>
    </div>
  );
};
