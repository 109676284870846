import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Create = (props) => {
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [Filter, setFilter] = useState("");

  const [ddlDepartment, setDDLDepartment] = useState([]);
  const [ddlCategory, setDDLCategory] = useState([]);
  const [ddlSubCat, setDDLSubCat] = useState([]);

  const refDescription = useRef();
  const refItemCodes = useRef();
  const refBays = useRef();
  const refDepartment = useRef();
  const refCategory = useRef();
  const refSubCat = useRef();
  const refStartDate = useRef();

  const refFilter_None = useRef();
  const refFilter_Group = useRef();
  const refFilter_ItemCodes = useRef();

  const refBtnCreate = useRef();

  useEffect(() => {
    async function getStockTake() {
      setStockTakeResponse("");
      const resObj = await runFetch(`${spURL}Stocktake_List_Division`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: "[{}]",
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLDepartment(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
    getStockTake();
  }, []);

  const handleChange_Filter = (e) => {
    setStockTakeResponse("");
    setFilter(e.target.value);
  };

  const handleChange_ddlDepartment = async () => {
    setStockTakeResponse("");
    if (refDepartment.current.value !== "") {
      const resObj = await runFetch(`${spURL}Stocktake_List_ItemCategory`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Division: refDepartment.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLCategory(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
  };

  const handleChange_ddlCategory = async () => {
    setStockTakeResponse("");
    if (refCategory.current.value !== "") {
      const resObj = await runFetch(`${spURL}Stocktake_List_ProductGroup`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Division: refDepartment.current.value,
            ItemCategory: refCategory.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLSubCat(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
  };

  const handleClick = async () => {
    setStockTakeResponse("");

    let filterInputCheckOk = false;

    switch (Filter) {
      case "GROUP":
        filterInputCheckOk = !(
          refDepartment.current.value === "" &&
          refCategory.current.value === "" &&
          refSubCat.current.value === ""
        );
        break;
      case "ITEMCODES":
        filterInputCheckOk = !(refItemCodes.current.value === "");
        break;
      default:
        filterInputCheckOk = true;
        break;
    }

    if (refDescription.current.value === "") {
      setStockTakeResponse("Description required.");
    } else if (refBays.current.value === "") {
      setStockTakeResponse("Bays required.");
    } else if (!filterInputCheckOk) {
      setStockTakeResponse("Filter required.");
    } else {
      const resObj = await runFetch(`${spURL}Stocktake_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: props.FromPage.StoreNo,
            Description: refDescription.current.value,
            Bays: refBays.current.value,
            Division: Filter === "GROUP" ? refDepartment.current.value : "",
            ItemCategory: Filter === "GROUP" ? refCategory.current.value : "",
            ProductGroup: Filter === "GROUP" ? refSubCat.current.value : "",
            ItemCodes:
              Filter === "ITEMCODES" ? refItemCodes.current.value.replaceAll("\n", ",") : "",
            StartDate: refStartDate.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setStockTakeResponse(spResponse.Table[0].OutputString);

        refBtnCreate.current.classList.add("hidden");

        refDescription.current.disabled = true;
        refBays.current.disabled = true;
        refStartDate.current.disabled = true;
        refDepartment.current.disabled = true;
        refCategory.current.disabled = true;
        refSubCat.current.disabled = true;
        refFilter_None.current.disabled = true;
        refFilter_Group.current.disabled = true;
        refFilter_ItemCodes.current.disabled = true;
        refItemCodes.current.disabled = true;
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Desc:</strong>
            </p>
            <input
              type="text"
              id="stocktake-create-description"
              ref={refDescription}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Bays:</strong>
            </p>
            <input
              type="text"
              id="stocktake-create-bays"
              ref={refBays}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Start Date:</strong>
            </p>
            <input
              type="date"
              required
              ref={refStartDate}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Filter on:</strong>
            </p>
            <div className="popup-radio-container">
              <input
                ref={refFilter_None}
                type="radio"
                id="none"
                name="filter"
                value="NONE"
                className="btnHover no-highlight"
                onChange={handleChange_Filter}
                defaultChecked
              />
              <label
                className="btnHover no-highlight"
                htmlFor="none"
              >
                None
              </label>
              <input
                ref={refFilter_Group}
                type="radio"
                id="group"
                name="filter"
                value="GROUP"
                className="btnHover no-highlight"
                onChange={handleChange_Filter}
              />
              <label
                className="btnHover no-highlight"
                htmlFor="group"
              >
                Groups
              </label>
              <input
                ref={refFilter_ItemCodes}
                type="radio"
                id="itemcodes"
                name="filter"
                value="ITEMCODES"
                className="btnHover no-highlight"
                onChange={handleChange_Filter}
              />
              <label
                className="btnHover no-highlight"
                htmlFor="itemcodes"
              >
                Items
              </label>
            </div>
          </div>
          <div className={Filter !== "GROUP" && "hidden"}>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Dept:</strong>
              </p>
              <select
                name="department"
                id="stocktake-create-department"
                onChange={handleChange_ddlDepartment}
                ref={refDepartment}
              >
                {ddlDepartment}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Cat:</strong>
              </p>
              <select
                name="department"
                id="stocktake-create-category"
                onChange={handleChange_ddlCategory}
                ref={refCategory}
              >
                {ddlCategory}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SubCat:</strong>
              </p>
              <select
                name="department"
                id="stocktake-create-subcat"
                ref={refSubCat}
              >
                {ddlSubCat}
              </select>
            </div>
          </div>

          <div className={Filter !== "ITEMCODES" && "hidden"}>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Item Codes</strong>
              </p>
              <textarea
                rows={5}
                ref={refItemCodes}
              ></textarea>
            </div>
          </div>

          <button
            className="signin-login-button btnHover"
            ref={refBtnCreate}
            onClick={handleClick}
          >
            Create
          </button>
        </div>

        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("menu")}
        />
        <FooterButton
          button="Home"
          colour={red}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
