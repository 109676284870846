import { useEffect, useState } from "react";
import { green, spURL } from "../../App";
import {
  format,
  subMonths,
  addMonths,
  startOfDay,
  endOfDay,
  startOfWeek,
  addDays,
  isSameDay,
  isPast,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  parseISO,
  startOfMonth,
  endOfMonth,
  getWeeksInMonth,
} from "date-fns";
import { PopupDeliveries } from "./PopupDeliveries";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { PopupUnblockTimeslot } from "./PopupUnblockTimeslot";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const IncomingDeliveriesBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [IncomingDeliveriesResponse, setIncomingDeliveriesResponse] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedInterval, setSelectedInterval] = useState("");

  const [bookedTimeslot, setBookedTimeslot] = useState([]);

  const [show, setShow] = useState(false);
  const [showUnblock, setShowUnblock] = useState(false);

  const onDateClickHandle = (day, dayStr, interval) => {
    // if not pastday && not blocked timeslot
    // if (!(isPast(day) && bookedTimeslot.filter(value => `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`)[0].Colour === 'GREY')) {
    if (!isPast(day)) {
      let timeslotColour = bookedTimeslot
        .map((item) => `${parseISO(item.Date)}-${item.Interval}`)
        .includes(`${day}-${interval}`)
        ? bookedTimeslot.filter(
            (value) => `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
          )[0].Colour
        : "";
      switch (timeslotColour) {
        case "RED":
          setSelectedDate(day.toLocaleDateString("en-US"));
          setSelectedInterval(interval);
          setShowUnblock(true);
          break;
        case "ORANGE":
        case "YELLOW":
        case "WHITE":
        case "":
          setSelectedDate(day.toLocaleDateString("en-US"));
          setSelectedInterval(interval);
          setShow(true);
          break;
        default:
          break;
      }
    }
  };

  const getDeliveries = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Deliveries_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          StartDate: startOfWeek(currentMonth, { weekStartsOn: 1 }).toLocaleDateString("en-US"),
        }),
      }),
    });
    if (resObj.response === null) {
      setIncomingDeliveriesResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setBookedTimeslot(
          spResponse.Table.map((item) => ({
            Date: item.DeliveryDate,
            Interval: item.DeliveryInterval,
            Colour: item.Colour,
            DeliverySummary: item.DeliverySummary,
          }))
        );
      }
    }
  };

  useEffect(() => {
    getDeliveries();
  }, [currentWeek, currentMonth]);

  useEffect(() => {}, [selectedDate, selectedInterval]);

  const renderStockTarget = (week) => {
    return (
      <div className="stock-target">
        <p>
          <strong>{week}</strong>
        </p>
      </div>
    );
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <>
        <div className="header row flex-middle">
          <div className="col"></div>
          <div className="col col-center">
            <span>{format(currentMonth, dateFormat)}</span>
          </div>
          <div className="col"></div>
        </div>
      </>
    );
  };
  const renderDays = (startDate) => {
    const dateFormat = "EEE";
    const days = [];
    days.push(<div className="col col-title"></div>);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="col col-center"
          key={i}
        >
          {format(addDays(startDate, i), dateFormat)}
          <br />
          {format(addDays(startDate, i), "dd")}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = (startDate) => {
    const endDate = lastDayOfWeek(startDate, { weekStartsOn: 1 });

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    for (let j = 0; j < 2; j++) {
      const interval = j === 0 ? "MORNING" : "AFTERNOON";
      while (day <= endDate) {
        days.push(
          <div className={`col cell col-title`}>
            <span className="number">
              {interval === "MORNING" ? (
                <>
                  Morning
                  <br />
                  (9am - 12pm)
                </>
              ) : (
                <>
                  Afternoon
                  <br />
                  (12pm - 4pm)
                </>
              )}
            </span>
          </div>
        );
        for (let i = 0; i < 7; i++) {
          formattedDate = format(day, dateFormat);
          const cloneDay = day;

          days.push(
            <div
              className={`col cell ${
                isPast(day, new Date())
                  ? "pastday"
                  : bookedTimeslot
                      .map((value) => `${parseISO(value.Date)}-${value.Interval}`)
                      .includes(`${day}-${interval}`)
                  ? `${bookedTimeslot
                      .filter(
                        (value) =>
                          `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
                      )[0]
                      .Colour.toLowerCase()}day`
                  : "whiteday"
              } `}
              key={`${day}-${interval} `}
              onClick={() => {
                const dayStr = format(cloneDay, "ccc dd MMM yy");
                onDateClickHandle(cloneDay, dayStr, interval);
              }}
            >
              {bookedTimeslot
                .map((value) => `${parseISO(value.Date)}-${value.Interval}`)
                .includes(`${day}-${interval}`)
                ? bookedTimeslot
                    .filter(
                      (value) =>
                        `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
                    )[0]
                    .DeliverySummary.split(",")
                    .map((item, index) => <p key={index}>{item}</p>)
                : ""}
            </div>
          );
          day = addDays(day, 1);
        }
      }
      day = startDate;
    }
    rows.push(
      <div
        className="row"
        key={day}
      >
        {days}
      </div>
    );
    days = [];

    return <div className="body">{rows}</div>;
  };

  const renderRolling4Weeks = () => {
    // get first day of current week
    let currentStartDayOfWeek = startOfWeek(currentMonth, { weekStartsOn: 1 });

    const weeks = [];

    // loop through all weeks of months and generate UI
    for (let currentWeek = 0; currentWeek < 4; currentWeek++) {
      weeks.push(renderStockTarget(`WEEK ${currentWeek + 1}`));
      weeks.push(renderDays(currentStartDayOfWeek));
      weeks.push(renderCells(currentStartDayOfWeek));

      currentStartDayOfWeek = addWeeks(currentStartDayOfWeek, 1);
    }

    return weeks;
  };

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-incomingdeliveriesbody-container"
        }
      >
        <div className="calendar">
          {renderHeader()}
          <p className="error-message">{IncomingDeliveriesResponse}</p>
          {renderRolling4Weeks()}

          <PopupDeliveries
            show={show}
            setShow={setShow}
            setIncomingDeliveriesResponse={setIncomingDeliveriesResponse}
            getDeliveries={getDeliveries}
            selectedDate={selectedDate}
            selectedInterval={selectedInterval}
          />

          <PopupUnblockTimeslot
            show={showUnblock}
            setShow={setShowUnblock}
            setIncomingDeliveriesResponse={setIncomingDeliveriesResponse}
            getDeliveries={getDeliveries}
            selectedDate={selectedDate}
            selectedInterval={selectedInterval}
          />
        </div>
      </div>
      <div className="footer-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
