import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ItemBarcode } from "../../components/ItemBarcode";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { getDistinctValuesFromArray } from "../../functions/GetDistinctValuesFromArray";
import { PopupPickQty } from "./PopupPickQty";

export const PickLines = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [LinesTable, setLinesTable] = useState([]);

  const [showPickQty, setShowPickQty] = useState(false);

  const emptyItem = {
    CartID: 0,
    Product: "",
    ItemNo: 0,
    QtyRequired: 0,
    QtyPicked: 0,
    Colour: "",
    Background: "",
    Iss: "",
  };
  const [SelectedItem, setSelectedItem] = useState(emptyItem);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PickOrders_Lines_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPage("menu");
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setLinesTable(
          spResponse.Table.map((item) => {
            let _background = "";
            switch (item.Colour) {
              case "Yellow":
                _background = "yellow";
                break;
              case "Green":
                _background = "lightgreen";
                break;
              default:
                _background = "white";
                break;
            }

            return {
              CartID: item.CartID,
              Product: item.Product,
              ItemNo: item.ItemNo,
              QtyRequired: item.QtyRequired,
              QtyPicked: item.QtyPicked,
              Colour: item.Colour,
              Background: `btnHover background-${_background}`,
              Iss: item.Iss,
            };
          })
        );
      }
    }

    if (document.getElementById("itembarcode-barcode")) {
      document.getElementById("itembarcode-barcode").value = "";
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    setWarehouseOrderingResponse("");

    props.setPage("menu");
  };

  const handleClick_Row = (_item) => {
    setWarehouseOrderingResponse("");
    setSelectedItem(_item);
    setShowPickQty(true);
  };

  const handleClick_Confirm = () => {
    props.setPage("pick-confirm");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <p className="error-message">{WarehouseOrderingResponse}</p>
        <div>
          {LinesTable.length === 0 ? (
            "No items found in order."
          ) : (
            <>
              <p>
                <strong>
                  Order{" "}
                  {getDistinctValuesFromArray(LinesTable.map((line) => line.CartID)).toString()}{" "}
                  Items List
                </strong>
              </p>
              <table
                className="itemdetails-table"
                style={{ fontSize: 14 }}
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>Cat/Desc/SKU</th>
                    <th>Req</th>
                    <th>Pkd</th>
                    <th>Iss</th>
                  </tr>
                </thead>
                <tbody>
                  {LinesTable.map((item, index) => (
                    <tr
                      key={index}
                      className={item.Background}
                      colour={item.Colour}
                      itemno={item.ItemNo}
                      onClick={() => handleClick_Row(item)}
                    >
                      <td>{item.CartID}</td>
                      <td dangerouslySetInnerHTML={{ __html: item.Product }}></td>
                      <td>{item.QtyRequired}</td>
                      <td>{item.QtyPicked}</td>
                      <td>{item.Iss}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>

        <PopupPickQty
          show={showPickQty}
          setShow={setShowPickQty}
          SelectedItem={SelectedItem}
          pageLoad={pageLoad}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Conf"
          colour={orange}
          onClick={handleClick_Confirm}
        />
      </div>
    </div>
  );
};
