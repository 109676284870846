import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Order } from "./Order";
import { Admin } from "./Admin";
import { Approve } from "./Approve";
import { ItemMaintenance } from "./ItemMaintenance";
import { PickHeader } from "./PickHeader";
import { PickLines } from "./PickLines";
import { PickConfirm } from "./PickConfirm";
import { PickManager } from "./PickManager";

export const WarehouseOrderingBody = (props) => {
  const [page, setPage] = useState("menu");

  const menuPage = <Menu setPage={setPage} />;

  const orderPage = (
    <Order
      page={page}
      setPage={setPage}
    />
  );

  const adminPage = (
    <Admin
      page={page}
      setPage={setPage}
    />
  );

  const approvePage = (
    <Approve
      page={page}
      setPage={setPage}
    />
  );

  const itemMaintenancePage = (
    <ItemMaintenance
      page={page}
      setPage={setPage}
    />
  );

  const pickHeaderPage = (
    <PickHeader
      page={page}
      setPage={setPage}
    />
  );

  const pickLinesPage = (
    <PickLines
      page={page}
      setPage={setPage}
    />
  );

  const pickConfirmPage = (
    <PickConfirm
      page={page}
      setPage={setPage}
    />
  );

  const pickManagerPage = (
    <PickManager
      page={page}
      setPage={setPage}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "order":
        return orderPage;
      case "admin":
        return adminPage;
      case "approve":
        return approvePage;
      case "item-maintenance":
        return itemMaintenancePage;
      case "pick-header":
        return pickHeaderPage;
      case "pick-lines":
        return pickLinesPage;
      case "pick-confirm":
        return pickConfirmPage;
      case "pick-manager":
        return pickManagerPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
