import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { purple } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => {
              props.setPage("loss-prevention-incident");
            }}
            button="LPI"
            colour={purple}
            title="Loss Prevention Incident"
          />
          <MenuButton
            onClick={() => {
              props.setPage("whs-incident");
            }}
            button="WI"
            colour={purple}
            title="WHS Incident"
          />
          <MenuButton
            onClick={() => {
              props.setPage("shopping-centre-incident");
            }}
            button="SCI"
            colour={purple}
            title="Shopping Centre Incident"
          />
        </div>
        <div className="home-table">
          <MenuButton
            onClick={() => {
              props.setPage("transport-incident");
            }}
            button="TI"
            colour={purple}
            title="Transport Incident"
          />
          <MenuButton
            onClick={() => {
              props.setPage("monthly-safety-audit");
            }}
            button="MSA"
            colour={purple}
            title="Monthly Safety Audit"
          />
          <MenuButton
            onClick={() => {
              props.setPage("expenditure-approval");
            }}
            button="EA"
            colour={purple}
            title="Expenditure Approval"
          />
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
