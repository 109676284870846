import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, green, red, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import Barcode from "react-barcode";
import { exportComponentAsPNG } from "react-component-export-image";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const StaffBarcode = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");
  const [staffBarcode, setStaffBarcode] = useState("123");

  const [currentUser, setCurrentUser] = useState([]);

  const handleChange_UserID = (event) => {
    const _userid = event.target.value;

    setCurrentUser((values) => ({
      ...values,
      UserID: _userid,
    }));
  };

  const handleClick_Search = async () => {
    setMenuResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_StaffBarcode_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: currentUser.UserID,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCurrentUser((values) => ({
          ...values,
          UserID: spResponse.Table[0].ID,
          FirstName: spResponse.Table[0]["First Name"],
          LastName: spResponse.Table[0]["Last Name"],
          StaffBarcode: spResponse.Table[0].StaffBarcode,
        }));
      } else {
        setMenuResponse("ERROR!: User not found.");
      }
    }
  };

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setPage("menu");
  };

  const handleClick_CreateBarcode = async () => {
    const resObj = await runFetch(`${spURL}Stocktake_StaffBarcode_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: currentUser.UserID,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStaffBarcode(spResponse.Table[0].StaffBarcode);
      }
    }

    await exportComponentAsPNG(componentRef, {
      fileName: `${currentUser.FirstName}_${currentUser.LastName}`,
    });
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ width: "fit-content" }}
    >
      <Barcode
        value={props.Barcode}
        displayValue="false"
      />
    </div>
  ));

  const componentRef = useRef();

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <div className="blockedorders-input-row">
          <p>
            <strong>UserID:</strong>
          </p>
          <input
            type="text"
            id="handheldpermission-userid"
            autoFocus
            onChange={handleChange_UserID}
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
          <ContentButton
            id="blockedorders-main-enter"
            colour={red}
            button="Go"
            onClick={handleClick_Search}
          />
        </div>
        <div className="blockedorders-content-container">
          {currentUser.length === 0 || currentUser.FirstName === undefined ? (
            ""
          ) : (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Name:</strong> {`${currentUser.FirstName} ${currentUser.LastName}`}
                </p>
              </div>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="Create Barcode"
                  colour={red}
                  button="CB"
                  onClick={handleClick_CreateBarcode}
                />
              </div>
              <div style={{ position: "absolute", top: -9999, left: -9999 }}>
                <ComponentToPrint
                  Barcode={staffBarcode}
                  ref={componentRef}
                />
              </div>
            </>
          )}

          <br />
          <p className="error-message">{MenuResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={red}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
