import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Menu } from "./Menu";
import { LossPreventionIncident } from "./LossPreventionIncident";
import { WHSIncident } from "./WHSIncident";
import { ShoppingCentreIncident } from "./ShoppingCentreIncident";
import { TransportIncident } from "./TransportIncident";
import { ExpenditureApproval } from "./ExpenditureApproval";
import { MonthlySafetyAudit } from "./MonthlySafetyAudit";

export const FormsBody = () => {
  const [page, setPage] = useState("menu");

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
    />
  );

  const lossPreventionIncidentPage = (
    <LossPreventionIncident
      page={page}
      setPage={setPage}
    />
  );

  const whsIncidentPage = (
    <WHSIncident
      page={page}
      setPage={setPage}
    />
  );

  const shoppingCentreIncidentPage = (
    <ShoppingCentreIncident
      page={page}
      setPage={setPage}
    />
  );

  const transportIncidentPage = (
    <TransportIncident
      page={page}
      setPage={setPage}
    />
  );

  const monthlySafetyAudit = (
    <MonthlySafetyAudit
      page={page}
      setPage={setPage}
    />
  );

  const expenditureApprovalPage = (
    <ExpenditureApproval
      page={page}
      setPage={setPage}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "loss-prevention-incident":
        return lossPreventionIncidentPage;
      case "whs-incident":
        return whsIncidentPage;
      case "shopping-centre-incident":
        return shoppingCentreIncidentPage;
      case "transport-incident":
        return transportIncidentPage;
      case "monthly-safety-audit":
        return monthlySafetyAudit;
      case "expenditure-approval":
        return expenditureApprovalPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
