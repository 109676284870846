import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAccessData, orange, spURL } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import {
  getDistinctObjectsFromArray,
  getDistinctValuesFromArray,
} from "../../functions/GetDistinctValuesFromArray";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { PopupAddToCart } from "./PopupAddToCart";
import { PopupOrder } from "./PopupOrder";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { PopupApproveOrder } from "./PopupApproveOrder";

export const Approve = (props) => {
  const [userid, ,] = store.useState("userid");

  const [WarehouseOrderingResponse, setWarehouseOrderingResponse] = useState("");

  const [SelectedStoreNo, setSelectedStoreNo] = useState("ALL");

  const [Orders, setOrders] = useState([]);

  const emptyOrder = {
    CartID: 0,
    StoreNo: "",
    StoreName: "",
    "Date Requested": "",
    "Requested by": "",
    WHInStockAmount: 0,
    WHNotInStockAmount: 0,
  };
  const [SelectedOrder, setSelectedOrder] = useState(emptyOrder);

  const [showApproveOrder, setShowApproveOrder] = useState(false);

  const getOrders = async (_signal) => {
    const resObj = await runFetch(`${spURL}Handheld_WarehouseOrdering_PendingOrders_Get`, {
      method: "POST",
      signal: _signal,
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setWarehouseOrderingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrders(spResponse.Table);
      } else {
        setOrders([]);
      }
    }
  };

  useInitialEffect(getOrders);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <select
            onChange={(e) => {
              setWarehouseOrderingResponse("");
              handleChange_Input(e, setSelectedStoreNo);
            }}
          >
            <option value="ALL">ALL</option>
            {getDistinctObjectsFromArray(
              Orders.map((item) => ({
                StoreNo: item.StoreNo,
                StoreName: item.StoreName,
              }))
            ).map((store, index) => (
              <option
                value={store.StoreNo}
                key={index}
              >
                {store.StoreName}
              </option>
            ))}
          </select>
        </div>

        <p className="error-message">{WarehouseOrderingResponse}</p>

        <table className="itemdetails-table">
          <thead>
            <tr>
              <th rowSpan={2}>Store</th>
              <th rowSpan={2}>OrderID</th>
              <th colSpan={2}>Items in WH?</th>
            </tr>
            <tr>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            {Orders.filter((order) => ["ALL", order.StoreNo].includes(SelectedStoreNo)).map(
              (order, index) => (
                <tr
                  key={index}
                  className="btnHover no-highlight"
                  onClick={() => {
                    setWarehouseOrderingResponse("");
                    setSelectedOrder(order);
                    setShowApproveOrder(true);
                  }}
                >
                  <td>{order.StoreName}</td>
                  <td>{order.CartID}</td>
                  <td>{order.WHInStockAmount}</td>
                  <td>{order.WHNotInStockAmount}</td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <PopupApproveOrder
          show={showApproveOrder}
          setShow={setShowApproveOrder}
          SelectedOrder={SelectedOrder}
          getOrders={getOrders}
          setWarehouseOrderingResponse={setWarehouseOrderingResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => props.setPage("admin")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
