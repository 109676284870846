import { useRef, useState } from "react";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { useId } from "react";
import { generateFormJSON } from "../../functions/GenerateFormJSON";
import store from "../../store";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

export const ExpenditureApproval = (props) => {
  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  );
  const containerClient = blobServiceClient.getContainerClient("handheldformsimages"); // create a containerClient

  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [FormResponse, setFormResponse] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [JobTitleList, setJobTitleList] = useState([]);

  const [Files, setFiles] = useState([]);

  const refForm = useRef();
  const refImageURL = useRef();

  const handleChange_FileUploader = async (uploadedFiles) => {
    setFormResponse("");

    const fileArr = Array.from(uploadedFiles).map((file, index) => ({
      name: `${storeno}_${new Date().toISOString()}_${index}.jpg`,
      imageLink: `https://tmportalimages.blob.core.windows.net/handheldformsimages/${storeno}_${new Date().toISOString()}_${index}.jpg`,
      type: file.type,
      size: file.size,
      stream: file.stream().getReader(),
      preview: URL.createObjectURL(file),
      blob: file,
    }));

    if (fileArr.length > 5) {
      setFormResponse("ERROR!: Too many files uploaded.");
    } else {
      setFiles(fileArr);
      refImageURL.current.value = fileArr.map((file) => file.imageLink);
    }
  };

  const renderFileRows = () => {
    let fileElement = [<></>];
    if (Files.length > 0) {
      fileElement = Files.map((file, index) => (
        <li
          key={index}
          data-name={file.name}
        >
          <div className="imagesimporttool-row file-row">
            <p>
              <img
                src={file.preview}
                alt={file.name}
              />
            </p>
            <p>{file.name}</p>
            <p>{file.size / 1000} kB</p>
            <p>
              <FontAwesomeIcon
                className="color-red btnHover"
                size="2x"
                icon={regular("circle-xmark")}
                onClick={() => handleClick_RemoveImage(file.name)}
              />
            </p>
          </div>
        </li>
      ));
    }
    return <>{fileElement}</>;
  };

  const handleClick_RemoveImage = (selectedFileName) => {
    setFormResponse("");

    if (selectedFileName !== "") {
      setFiles(Files.filter((file) => file.name !== selectedFileName));
    }
  };

  const saveForm = async () => {
    let formData = new FormData(refForm.current);

    const resObj = await runFetch(`${spURL}Handheld_Forms_Log_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          StoreNo: storeno,
          FormType: "Expenditure Approval",
          FormContentJSON: generateFormJSON(formData),
        }),
      }),
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setFormResponse(spResponse.Table[0].OutputString);

      refForm.current.reset();
      setFiles([]);
    }
  };

  const handleFormSubmit = async () => {
    setFormResponse("");

    let isFormValid = refForm.current.checkValidity();

    if (!isFormValid) {
      refForm.current.reportValidity();
    } else {
      try {
        Files.forEach(async (file) => {
          const blobName = file.name; // Specify a default blob name if needed
          const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
          await blobClient.uploadData(file.blob); // upload the image
        });

        saveForm();
      } catch (error) {
        setFormResponse("ERROR!: Image upload error.");
      }
    }
  };

  const getStoreList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_StoreList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(spResponse.Table.map((item) => item.StoreName));
      }
    }
  };

  const getJobTitleList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_JobTitleList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setJobTitleList(spResponse.Table.map((item) => item.JobTitle));
      }
    }
  };

  useInitialEffect(getStoreList);
  useInitialEffect(getJobTitleList);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1>
          <strong>Expenditure Approval</strong>
        </h1>
        <hr />
        <p className="error-message">{FormResponse}</p>
        <form ref={refForm}>
          <h5>
            <strong>Store Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Name:*</strong>
              </p>
              <select
                name="StoreName"
                required
              >
                <option value=""></option>
                {StoreList.map((storename) => (
                  <option value={storename}>{storename}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Phone:</strong>
              </p>
              <input
                type="text"
                name="StorePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfReport"
                required
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Requested by:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="RequestedBy_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="RequestedBy_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="RequestedBy_Phone"
              />
            </div>
          </div>
          <hr />
          <div className="stocktake-create-input-row">
            <p>
              <strong>Details of Expenditure:*</strong>
            </p>
            <textarea
              rows={10}
              required
              name="ExpenditureDetails"
            ></textarea>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Amount Requested:*</strong>
            </p>
            <input
              type="text"
              required
              name="RequestedAmount"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>If applicable, please attach quotes</strong>
            </p>
            <FileUploader
              multiple={true}
              name="UploadedQuotes"
              handleChange={handleChange_FileUploader}
              types={["png", "jpg", "jpeg", "webp"]}
            />
          </div>
          {Files.length > 0 && (
            <ul className="imagesimporttool-filelist">
              <li>
                <div className="imagesimporttool-row title-row">
                  <p>
                    <strong>Image</strong>
                  </p>
                  <p>
                    <strong>Name</strong>
                  </p>
                  <p>
                    <strong>Size</strong>
                  </p>
                  <p></p>
                </div>
              </li>
              {renderFileRows()}
            </ul>
          )}
          <input
            type="text"
            className="hidden"
            name="UploadedQuotesImageURL"
            ref={refImageURL}
          />
        </form>
      </div>
      <div className="footer-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={purple}
          onClick={handleFormSubmit}
        />
      </div>
    </div>
  );
};
