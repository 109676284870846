import { useRef, useState } from "react";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { useInitialEffect } from "../../hooks/useInitialEffect";
import { runFetch } from "../../functions/RunFetch";
import { useId } from "react";
import { generateFormJSON } from "../../functions/GenerateFormJSON";
import store from "../../store";

export const ShoppingCentreIncident = (props) => {
  const [userid, ,] = store.useState("userid");
  const [storeno, ,] = store.useState("storeno");

  const [FormResponse, setFormResponse] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [JobTitleList, setJobTitleList] = useState([]);

  const ItemsAffected_Lifts = useId();
  const ItemsAffected_Escalators = useId();
  const ItemsAffected_Doors = useId();
  const ItemsAffected_Leaks = useId();
  const ItemsAffected_Renovation = useId();
  const ItemsAffected_Lighting = useId();
  const ItemsAffected_CarParkIssues = useId();
  const ItemsAffected_RoadClosure = useId();

  const refForm = useRef();

  const handleFormSubmit = async () => {
    setFormResponse("");

    let isFormValid = refForm.current.checkValidity();
    let formData = new FormData(refForm.current);

    if (!isFormValid) {
      refForm.current.reportValidity();
    } else {
      const resObj = await runFetch(`${spURL}Handheld_Forms_Log_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
            StoreNo: storeno,
            FormType: "Shopping Centre Incident Form",
            FormContentJSON: generateFormJSON(formData),
          }),
        }),
      });

      if (resObj.response === null) {
        setFormResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setFormResponse(spResponse.Table[0].OutputString);

        refForm.current.reset();
      }
    }
  };

  const getStoreList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_StoreList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(spResponse.Table.map((item) => item.StoreName));
      }
    }
  };

  const getJobTitleList = async (signal) => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Forms_JobTitleList_Get`, {
      method: "POST",
      signal: signal,
    });

    if (resObj.response === null) {
      setFormResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setJobTitleList(spResponse.Table.map((item) => item.JobTitle));
      }
    }
  };

  useInitialEffect(getStoreList);
  useInitialEffect(getJobTitleList);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1>
          <strong>Shopping Centre Incident</strong>
        </h1>
        <hr />
        <p className="error-message">{FormResponse}</p>
        <form ref={refForm}>
          <h5>
            <strong>Store Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Name:*</strong>
              </p>
              <select
                name="StoreName"
                required
              >
                <option value=""></option>
                {StoreList.map((storename) => (
                  <option value={storename}>{storename}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store Phone:</strong>
              </p>
              <input
                type="text"
                name="StorePhone"
              />
            </div>
          </div>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Date of Report:*</strong>
              </p>
              <input
                type="datetime-local"
                name="DateOfReport"
                required
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported by:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedBy_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedBy_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Reported to:</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:*</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Name"
                required
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:*</strong>
              </p>
              <select
                name="ReportedTo_JobTitle"
                required
              >
                <option value=""></option>
                {JobTitleList.map((jobtitle) => (
                  <option value={jobtitle}>{jobtitle}</option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <input
                type="text"
                name="ReportedTo_Phone"
              />
            </div>
          </div>
          <hr />
          <h5>
            <strong>Incident Information</strong>
          </h5>
          <p>The two employees listed above must complete this form together.</p>
          <p>
            <strong>Items Affected:</strong>
          </p>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Lifts}
                name="ItemsAffected"
                value="Lift (Elevators)"
              />
              <label htmlFor={ItemsAffected_Lifts}>Lift (Elevators)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Escalators}
                name="ItemsAffected"
                value="Travelators (Escalators)"
              />
              <label htmlFor={ItemsAffected_Escalators}>Travelators (Escalators)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Doors}
                name="ItemsAffected"
                value="Doors (Automatic & Manual)"
              />
              <label htmlFor={ItemsAffected_Doors}>Doors (Automatic & Manual)</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Leaks}
                name="ItemsAffected"
                value="Leaks"
              />
              <label htmlFor={ItemsAffected_Leaks}>Leaks</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Renovation}
                name="ItemsAffected"
                value="Centre Work (Renovation)"
              />
              <label htmlFor={ItemsAffected_Renovation}>Centre Work (Renovation)</label>
            </div>
          </div>
          <div className="popup-radio-container">
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_Lighting}
                name="ItemsAffected"
                value="Lighting"
              />
              <label htmlFor={ItemsAffected_Lighting}>Lighting</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_CarParkIssues}
                name="ItemsAffected"
                value="Car Park Issues"
              />
              <label htmlFor={ItemsAffected_CarParkIssues}>Car Park Issues</label>
            </div>
            <div className="printstoreaddress-settings-radio-container">
              <input
                type="checkbox"
                id={ItemsAffected_RoadClosure}
                name="ItemsAffected"
                value="Road Closure"
              />
              <label htmlFor={ItemsAffected_RoadClosure}>Road Closure</label>
            </div>
          </div>
          <hr />
          <h5>
            <strong>Centre Management Details</strong>
          </h5>
          <div className="two-input-row">
            <div className="stocktake-create-input-row">
              <p>Name:</p>
              <input
                type="text"
                name="CentreManagementName"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>Phone:</p>
              <input
                type="text"
                name="CentreManagementPhone"
              />
            </div>
          </div>
          <hr />
          <p>
            <strong>What Happened?</strong>
          </p>
          <textarea
            rows={5}
            name="WhatHappened"
          ></textarea>
          <hr />
          <p>
            <strong>Add Photos via Email</strong>
          </p>
          <p>Please send relevant photos via Email to relevant Head Office Contact</p>
          <hr />
          <div className="stocktake-create-input-row">
            <p>
              <strong>End Date of Work</strong>
            </p>
            <input
              type="date"
              required
              name="EndDateOfWork"
            />
          </div>
          <hr />
          <h5>
            <strong>Head Office Contact</strong>
          </h5>
          <div className="three-input-row">
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:</strong>
              </p>
              <p>Kevin Fine/Idan Levy</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Job Title:</strong>
              </p>
              <p>:)</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Phone:</strong>
              </p>
              <p>haha let's guess what their contact is</p>
            </div>
          </div>
        </form>
      </div>
      <div className="footer-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={purple}
          onClick={handleFormSubmit}
        />
      </div>
    </div>
  );
};
